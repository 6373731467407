<template>
  <div class="create-content-main edit-content-main" style="position: relative">
    <div class="loading-ad" v-if="loadingUpload">
      <b-spinner class="cus-spinner-ad" large></b-spinner>
    </div>
    <div class="title-content justify-content-end" style="margin: 0">
      <div>
        <b-button
          class="back-btn"
          variant="secondary"
          v-on:click="returnList()"
        >
          戻る
        </b-button>
        <b-button
          variant="info"
          style="background: red; color: #fff; border: none"
        >
          オファー設定
        </b-button>
        <b-button
          variant="info"
          style="background: #e5e5e5; color: #000; border: none"
          v-b-modal.modal-confirm-content
        >
          コンテンツ公開設定
        </b-button>
      </div>
    </div>
    <div
      class="create-slag-form background-content-admin"
      style="padding-top: 12px"
    >
      <div class="create-slag-form">
        <div class="row ml-0 pt-2">
          <h2
            class="col col-auto font-title font-weight-bold cpx-4 my-auto header-font position-relative"
            style="margin-right: 10px; bottom: 5px"
          >
            オファー公開設定
          </h2>
          <div class="col col-auto">
            <div class="mx-3">
              <label
                v-for="(item, index) in content.product_type != 3
                  ? listSetting
                  : listSetting3"
                :key="index"
                class="mr-3 position-relative py-2"
                :class="
                  typeSetting === item.value
                    ? 'btn-tab-sale-active'
                    : 'btn-tab-sale-inactive'
                "
                @click="typeSetting = item.value"
                style="font-weight: normal !important; font-size: 17px"
              >
                {{ item.text }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div v-if="typeSetting === 1">
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >オファー内容設定
            <span class="required text-nowrap my-auto px-2">(必須)</span>
            </label
          >
          <div class="w-100 cp-4">
            <div class="btn-toggle-active">
              <input
                v-model="content.product_type"
                class="m-0"
                type="radio"
                name="offertContentSetting"
                value="0"
              />
              <c-button class="btn-checkbox-active btn-inactive-radio">
                オンライン販売
              </c-button>
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="content.product_type"
                class="m-0"
                type="radio"
                name="offertContentSetting"
                value="1"
              />
              <c-button class="btn-inactive-radio btn-checkbox-active px-4"
                >物販</c-button
              >
            </div>
            <!-- <div class="btn-toggle-active">
              <input
                v-model="content.product_type"
                class="m-0"
                type="radio"
                name="offertContentSetting"
                value="2"
              />
              <c-button class="btn-inactive-radio btn-checkbox-active px-4"
                >簡単決済</c-button
              >
            </div> -->
            <!-- <div class="btn-toggle-active">
              <input
                v-model="content.product_type"
                class="m-0"
                type="radio"
                name="offertContentSetting"
                value="3"
              />
              <c-button class="btn-inactive-radio btn-checkbox-active px-4"
                >自由金額入力</c-button
              >
            </div> -->
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label">
            商品タイトル
            <span class="required text-nowrap my-auto px-2">(必須)</span>
          </label>
          <div class="w-100 cp-4">
            <div class="w-100 d-flex">
              <input
                v-model="content.product_title"
                class="form-input m-0 m-0"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label">
            著者
            <span class="required text-nowrap my-auto px-2">(必須)</span>
          </label>
          <div class="w-100 cp-4">
            <b-form-select
              class="w-100 custome-text-select"
              id="select-auth"
              v-model="content.author_id"
              tag-placeholder="Add this as author"
              :options="listAuthorOptions"
            ></b-form-select>
          </div>
        </div>
      </div>
      <div v-if="typeSetting === 2">
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label">価格設定</label>
          <div class="w-100 cp-4 py-1">
            <div class="title-btn mt-0">
              <label
                v-for="(item, indexPricePlan) in listPrice"
                :key="indexPricePlan"
                class="mr-3 position-relative"
                :class="
                  indexPricePlan == isSelectedPrice
                    ? 'btn-tab-sale-active'
                    : 'btn-tab-sale-inactive'
                "
                v-on:click="ClickPriceSelected(item.value, indexPricePlan)"
              >
                {{ item.text }}
              </label>
              <div style="display: none">
                <b-button
                  v-if="listPrice.length < 5"
                  class="mr-3"
                  variant="success"
                  @click="addPrice(listPrice.length)"
                  style="border-radius: 50% !important"
                >
                  <font-awesome-icon
                    class="sidebar-icon"
                    :icon="['fas', 'plus']"
                  />
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(valuePrice, indexPrice) in content.price"
          :key="indexPrice"
        >
          <div v-if="indexPrice == isSelectedPrice">
            <div v-if="content.product_type != 3">
              <div class="row row-input">
                <label class="col-sm-12 font-weight-bold cp-label"
                  >ステップ１：単発or月額</label
                >
                <div class="w-100 cp-4">
                  <div class="btn-toggle-active">
                    <input
                      v-model="valuePrice.sale_format"
                      class="m-0"
                      type="radio"
                      name="saleMethod"
                      id="saleMethod_0"
                      value="0"
                    />
                    <label
                      for="saleMethod_0"
                      :class="
                        valuePrice.sale_format == 0
                          ? 'btn-share-freesize-active py-2 px-4'
                          : 'btn-share-freesize py-2 px-4'
                      "
                    >
                      単発販売
                    </label>
                  </div>
                  <div class="btn-toggle-active">
                    <input
                      v-model="valuePrice.sale_format"
                      class="m-0"
                      type="radio"
                      name="saleMethod"
                      id="saleMethod_1"
                      value="1"
                    />
                    <label
                      for="saleMethod_1"
                      :class="
                        valuePrice.sale_format == 1
                          ? 'btn-share-freesize-active py-2 px-4'
                          : 'btn-share-freesize py-2 px-4'
                      "
                    >
                      月額販売
                    </label>
                  </div>
                </div>
                <div
                  class="col-lg-12 align-items-center d-flex justify-content-center"
                >
                  <div
                    id="wrap-sub-form mx-auto"
                    class="w-75"
                    v-if="valuePrice.sale_format == 1"
                  >
                    <div class="row mb-3 row-input">
                      <label class="col-lg-3 font-weight-bold pl-0 my-auto"
                        >初回費用を設定する:</label
                      >
                      <input
                        class="form-input m-0 col-lg-4"
                        type="number"
                        v-model="valuePrice.initialCost"
                        :maxlength="10"
                        :min="0"
                        placeholder="0"
                        oninvalid="this.setCustomValidity('番号を入力してください')"
                        oninput="this.setCustomValidity('')"
                        onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                      />
                    </div>
                    <div class="row mb-3 row-input">
                      <label
                        class="col-lg-3 col-12 font-weight-bold pl-0 my-auto"
                        >お試し期間を設定する:</label
                      >
                      <input
                        class="form-input m-0 col-lg-4 col-11"
                        type="number"
                        v-model="valuePrice.trialPeriod"
                        :maxlength="10"
                        :min="0"
                        placeholder="0"
                        oninvalid="this.setCustomValidity('番号を入力してください')"
                        oninput="this.setCustomValidity('')"
                        onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                      />
                      <div class="col-lg-4 col-1 my-auto">
                        <span>日</span>
                      </div>
                    </div>
                    <div class="row mb-3 row-input">
                      <label class="col-lg-3 font-weight-bold pl-0 my-auto"
                        >請求間隔を変更する:</label
                      >
                      <div class="col-lg-9 custom-disabled pl-0">
                        <label
                          class="custom-ListLabel mt-0"
                          v-bind:class="{
                            selected: index + 1 == isSelected,
                          }"
                          v-on:click="ClickContentSelected(item.value, index)"
                          v-for="(item, index) in listActionType"
                          :key="index"
                          style="padding: 10px 40px"
                        >
                          {{ item.text }}
                        </label>
                      </div>
                    </div>
                    <div class="row mb-3 row-input">
                      <label
                        class="col-lg-3 col-12 font-weight-bold pl-0 my-auto"
                        >請求間隔を追加する:</label
                      >
                      <input
                        class="form-input m-0 col-lg-4 col-8"
                        type="number"
                        v-model="valuePrice.addBill"
                        :maxlength="10"
                        :min="0"
                        placeholder="0"
                        oninvalid="this.setCustomValidity('番号を入力してください')"
                        oninput="this.setCustomValidity('')"
                        onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                      />
                      <div class="col-lg-4 col-4 my-auto">
                        <span>{{ valuePrice.textChange }}に1回請求する</span>
                      </div>
                    </div>
                    <div class="row mb-3 row-input">
                      <label class="col-lg-3 font-weight-bold pl-0 my-auto"
                        >請求回数を設定する:</label
                      >
                      <input
                        class="form-input m-0 col-lg-4"
                        type="number"
                        v-model="valuePrice.billingCount"
                        :maxlength="10"
                        :min="0"
                        placeholder="0"
                        oninvalid="this.setCustomValidity('番号を入力してください')"
                        oninput="this.setCustomValidity('')"
                        onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="row row-input">
                  <label class="col-sm-12 font-weight-bold cp-label">
                    ステップ２：基本価格
                    <span class="required text-nowrap my-auto px-2"
                      >(必須)</span
                    >
                  </label>
                  <div class="w-100 cp-4 d-flex">
                    <div class="w-25 d-flex align-items-center mr-5">
                      <!-- Memo: number型にすれば okeypress は不要？(hamada) -->
                      <input
                        class="form-input m-0 m-0"
                        type="text"
                        onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                        v-model="valuePrice.product_price"
                      />&nbsp;&nbsp;円
                    </div>

                    <div class="d-flex align-items-center mr-5">
                      <span class="d-inline-block text-nowrap mr-1">付与するポイント：</span>
                      <input
                        class="form-input m-0 m-0"
                        type="number"
                        v-model="valuePrice.give_point"
                      />
                      <span class="d-inline-block text-nowrap ml-1">ポイント</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row row-input">
                <label class="col-sm-12 font-weight-bold cp-label">
                  ステップ３：決済方法
                  <span class="required text-nowrap my-auto px-2">(必須)</span>
                </label>
                <div class="w-100 cp-4" v-if="valuePrice.sale_format == 0">
                  <div
                    class="btn-toggle-active p-1"
                    v-for="(
                      valuePayment, indexPayment
                    ) in content.product_type != 2
                      ? optionsDefault_payment
                      : optionsQuickDefaultPayment"
                    :key="indexPayment"
                  >
                    <input
                      v-model="valuePrice.defaultPayment"
                      class="m-0"
                      type="radio"
                      name="defaultPayment"
                      :value="valuePayment.value"
                    />
                    <b-button class="btn-common btn-checkbox-active">{{
                      valuePayment.text
                    }}</b-button>
                  </div>
                </div>
                <div class="w-100 p-3" v-if="valuePrice.sale_format == 1">
                  <div
                    class="btn-toggle-active"
                    v-for="(
                      valuePayment, indexPayment
                    ) in content.product_type != 2
                      ? optionsSubscriptionDefault_payment
                      : optionsQuickDefaultPayment"
                    :key="indexPayment"
                  >
                    <input
                      v-model="valuePrice.defaultSubscription"
                      class="m-0"
                      type="radio"
                      name="defaultSubscription"
                      :value="valuePayment.value"
                    />
                    <b-button class="btn-common btn-checkbox-active">{{
                      valuePayment.text
                    }}</b-button>
                  </div>
                </div>
              </div>
              <div v-if="valuePrice.defaultPayment.indexOf(3) !== -1 && valuePrice.sale_format === '0'" class="row row-input">
                <label class="col-sm-12 font-weight-bold cp-label">
                  ステップ４：利用予定の外部決済URLをご入力ください。
                  <span class="required text-nowrap my-auto px-2">(必須)</span>
                </label>
                <div class="w-100 d-flex cp-4">
                  <span class="text-nowrap my-auto" style="min-width: 130px">決済URL</span>
                  <input
                    class="form-input m-0"
                    type="text"
                    v-model="valuePrice.externalPaymentUrl"
                  />
                </div>
              </div>
              <div
                v-if="
                  valuePrice.defaultPayment.indexOf(3) !== -1 &&
                  valuePrice.defaultPayment.length === 1 &&
                  valuePrice.sale_format === '0'
                "
                class="row row-input"
                >
                <label class="col-sm-12 font-weight-bold cp-label">
                  ステップ５：本システムの入力フォームをスキップしますか？（スキップするとログイン後にすぐ決済URLへ移動します）
                </label>
                <div class="w-100 cp-4" v-if="valuePrice.sale_format == 0">
                  <div
                    class="btn-toggle-active p-1"
                    v-for="(valueExternalPaymentUrlSkip, indexExternalPaymentUrlSkip) in externalPaymentUrlSkip"
                    :key="indexExternalPaymentUrlSkip"
                  >
                    <input
                        v-model="valuePrice.externalPaymentUrlSkip"
                        class="m-0"
                        type="radio"
                        name="externalPaymentUrlSkip"
                        :value="valueExternalPaymentUrlSkip.value"
                      />
                    <b-button class="btn-common btn-checkbox-active">{{
                      valueExternalPaymentUrlSkip.text
                    }}</b-button>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  (
                    valuePrice.defaultPayment.indexOf(3) === -1 ||
                    valuePrice.defaultPayment.length === 1
                  ) &&
                  valuePrice.sale_format === '0'
                "
                class="w-100 row-input"
                >
              </div>
              <div v-if="valuePrice.defaultPayment.indexOf(3) !== -1 && valuePrice.sale_format === '0'" class="row row-input">
                <label class="col-sm-12 font-weight-bold cp-label">
                  ステップ{{ valuePrice.defaultPayment.length === 2 ? '５' : '６' }}：注文の承認は手動にされますか？（決済があっても、自動で承認しない）
                </label>
                <div class="w-100 cp-4" v-if="valuePrice.sale_format == 0">
                  <div
                    class="btn-toggle-active p-1"
                    v-for="(valueExternalPaymentAutoApproval, indexExternalPaymentAutoApproval) in externalPaymentAutoApproval"
                    :key="indexExternalPaymentAutoApproval"
                  >
                    <input
                        v-model="valuePrice.externalPaymentAutoApproval"
                        class="m-0"
                        type="radio"
                        name="valueExternalPaymentAutoApproval"
                        :value="valueExternalPaymentAutoApproval.value"
                      />
                    <b-button class="btn-common btn-checkbox-active">{{
                      valueExternalPaymentAutoApproval.text
                    }}</b-button>
                  </div>
                </div>
                <template v-if="valuePrice.externalPaymentAutoApproval === 2">
                  <div class="w-100 cp-4">
                    <div
                      class="btn-toggle-active p-1"
                      v-for="(valueExternalPaymentReferrerCheckType, index) in externalPaymentReferrerCheckType"
                      :key="index"
                    >
                      <input
                          v-model="valuePrice.externalPaymentReferrerCheckType"
                          class="m-0"
                          type="radio"
                          name="valueExternalPaymentReferrerCheckType"
                          :value="valueExternalPaymentReferrerCheckType.value"
                        />
                      <b-button class="btn-common btn-checkbox-active">{{
                        valueExternalPaymentReferrerCheckType.text
                      }}</b-button>
                    </div>
                  </div>
                  <div class="w-100 d-flex cp-4">
                    <span class="text-nowrap my-auto" style="min-width: 130px">リファラーURL</span>
                    <input
                      class="form-input m-0"
                      type="text"
                      v-model="valuePrice.externalPaymentReferrerCheckText"
                    />
                  </div>
                </template>
              </div>
            </div>
            <div v-else>
              <div class="row row-input">
                <label class="col-sm-12 font-weight-bold cp-label">
                  最低入力価格
                  <span class="required text-nowrap my-auto px-2">(必須)</span>
                </label>
                <div class="w-100 cp-4 d-flex">
                  <div class="w-25 d-flex align-items-center mr-5">
                    <input
                      class="form-input m-0 m-0"
                      type="text"
                      onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                      v-model="valuePrice.minInputPrice"
                    />&nbsp;&nbsp;円
                  </div>
                </div>
              </div>
              <div class="row row-input">
                <label class="col-sm-12 font-weight-bold cp-label">
                  決済方法
                  <span class="required text-nowrap my-auto px-2">(必須)</span>
                </label>
                <div class="w-100 cp-4">
                  <div
                    class="btn-toggle-active"
                    v-for="(valuePayment, indexPayment) in optionsMinInputPrice"
                    :key="indexPayment"
                  >
                    <input
                      v-model="valuePrice.defaultMinInputPrice"
                      class="m-0"
                      type="radio"
                      name="defaultMinInputPrice"
                      :value="valuePayment.value"
                    />
                    <b-button class="btn-common btn-checkbox-active">{{
                      valuePayment.text
                    }}</b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row row-input" v-if="content.product_type != 3 && valuePrice.defaultPayment.indexOf(3) === -1">
              <label class="col-sm-12 font-weight-bold cp-label">
                オプション：期間限定価格
              </label>
              <div class="w-100 cp-4">
                <div class="w-100 d-flex">
                  <input
                    class="form-input m-0 m-0"
                    type="text"
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    v-model="valuePrice.priceOverTime"
                  />
                </div>
                <div class="mt-3 mb-3 row-input d-flex align-items-center">
                  <label>期間設定（全ユーザー）</label>
                  <div class="row row-input">
                    <div
                      class="fd-admin-listproperty-card-filter filter-date d-flex"
                    >
                      <date-picker
                        v-model="valuePrice.fromTimeRelease"
                        name="date"
                        :config="optionsDate"
                        autocomplete="off"
                        language="jp"
                      ></date-picker>
                      <span class="my-auto" style="font-size: 23px">~</span>
                      <date-picker
                        v-model="valuePrice.toTimeRelease"
                        name="date"
                        :config="optionsDate"
                        autocomplete="off"
                      ></date-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >ステップ{{
                  valuePrice.defaultPayment.indexOf(3) === -1
                    ? '４'
                    : valuePrice.defaultPayment.length === 2
                    ? '６'
                    : '７'
                }}：決済後の移遷先URL（ご希望のサンクスページをご入力ください）</label
              >
              <div class="w-100 cp-4">
                <div class="btn-toggle-active">
                  <input
                    v-model="valuePrice.typeUrlPageThank"
                    class="m-0"
                    type="radio"
                    name="typeUrlPageThank"
                    id="typeUrlPageThank_0"
                    :value="0"
                  />
                  <label
                    for="typeUrlPageThank_0"
                    :class="
                      valuePrice.typeUrlPageThank == 0
                        ? 'btn-share-freesize-active py-2 px-4'
                        : 'btn-share-freesize py-2 px-4'
                    "
                  >
                    固定
                  </label>
                </div>
                <div class="btn-toggle-active">
                  <input
                    v-model="valuePrice.typeUrlPageThank"
                    class="m-0"
                    type="radio"
                    name="typeUrlPageThank"
                    id="typeUrlPageThank_1"
                    :value="1"
                  />
                  <label
                    for="typeUrlPageThank_1"
                    :class="
                      valuePrice.typeUrlPageThank == 1
                        ? 'btn-share-freesize-active py-2 px-4'
                        : 'btn-share-freesize py-2 px-4'
                    "
                  >
                    分岐
                  </label>
                </div>
              </div>
              <div class="w-100" v-if="valuePrice.typeUrlPageThank == 0">
                <div class="w-100 d-flex cp-4" v-if="valuePrice.defaultPayment.indexOf(0) !== -1 || valuePrice.defaultPayment.indexOf(1) !== -1">
                  <span class="text-nowrap my-auto" style="min-width: 130px"
                    >クレジットカード</span
                  >
                  <input
                    class="form-input m-0"
                    type="text"
                    v-model="valuePrice.urlPageThankCredit"
                  />
                </div>
                <div class="w-100 d-flex" v-if="valuePrice.defaultPayment.indexOf(2) !== -1">
                  <span
                    class="text-nowrap my-auto cp-4"
                    style="min-width: 130px"
                    >銀行振込</span
                  >
                  <div class="w-100 cp-4">
                    <input
                      class="form-input m-0"
                      type="text"
                      v-model="valuePrice.urlPageThankTransfer"
                    />
                  </div>
                </div>
                <div class="w-100 d-flex cp-4" v-if="valuePrice.defaultPayment.indexOf(3) !== -1">
                  <span class="text-nowrap my-auto" style="min-width: 130px"
                    >URL</span
                  >
                  <input
                    class="form-input m-0"
                    type="text"
                    v-model="valuePrice.externalPaymentThankUrl"
                  />
                </div>
              </div>
              <div class="w-100" v-if="valuePrice.typeUrlPageThank == 1">
                <div
                  class="w-50 d-flex mt-3 mb-3"
                  v-for="(
                    valuePageThank, indexPageThank
                  ) in valuePrice.listSlagSetting"
                  :key="indexPageThank"
                >
                  <div class="w-100">
                    <div class="d-flex">
                      <div class="w-100">
                        <div class="w-100 d-flex cp-4 cus-inp" v-if="valuePrice.defaultPayment.indexOf(0) !== -1 || valuePrice.defaultPayment.indexOf(1) !== -1">
                          <span
                            class="text-nowrap my-auto"
                            style="min-width: 140px"
                            >クレジットカード</span
                          >
                          <input
                            class="form-input m-0"
                            type="text"
                            v-model="valuePageThank.urlCreditCard"
                            style="margin: 0"
                          />
                        </div>
                        <div class="w-100 d-flex cp-4 cus-inp mt-3" v-if="valuePrice.defaultPayment.indexOf(2) !== -1">
                          <span
                            class="text-nowrap my-auto"
                            style="min-width: 140px"
                            >銀行振込</span
                          >
                          <input
                            class="form-input m-0"
                            type="text"
                            v-model="valuePageThank.urlTransfer"
                            style="margin: 0"
                          />
                        </div>
                        <div class="w-100 d-flex cp-4 cus-inp mt-3" v-if="valuePrice.defaultPayment.indexOf(3) !== -1">
                          <span
                            class="text-nowrap my-auto"
                            style="min-width: 140px"
                            >URL</span
                          >
                          <input
                            class="form-input m-0"
                            type="text"
                            v-model="valuePageThank.urlExternalPayment"
                            style="margin: 0"
                          />
                        </div>
                      </div>
                      <div class="cus-centre">
                        <div>
                          <b-icon
                            icon="gear-fill"
                            class="my-auto ml-2 bt-setting"
                            font-scale="2"
                            style="border-color: #ced2d8; padding: 4px"
                            v-on:click="
                              setCondition(
                                valuePageThank.conditions,
                                indexPageThank
                              )
                            "
                          />
                        </div>
                      </div>
                      <div
                        class="cus-centre"
                        v-if="
                          valuePrice.listSlagSetting &&
                          valuePrice.listSlagSetting.length > 1
                        "
                      >
                        <div>
                          <b-button
                            variant="dark"
                            class="rounded-circle custome-btn-sub float-right mt-0 bt-sub"
                            style="border-radius: 50% !important"
                            v-on:click="
                              subSetting(
                                valuePrice.listSlagSetting,
                                indexPageThank
                              )
                            "
                            ><b-icon icon="dash" scale="2"
                          /></b-button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                      <b-button
                        variant="success"
                        class="rounded-circle custome-btn-add cus-bt-color"
                        style="border-radius: 50% !important"
                        v-on:click="
                          addSettingRedirect(
                            valuePrice.listSlagSetting,
                            indexPageThank
                          )
                        "
                        ><b-icon
                          class="sidebar-icon"
                          icon="plus"
                          font-scale="2"
                      /></b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row row-input" v-if="valuePrice.defaultPayment.indexOf(3) !== -1 && valuePrice.sale_format === '0'">
              <label class="col-sm-12 font-weight-bold cp-label">
                ステップ{{ valuePrice.defaultPayment.length === 2 ? '７' : '８' }}：以下の情報を、ご利用の決済サービスの「リダイレクト設定URL」に設定をお願いします。
              </label>
              <div class="w-100 d-flex cp-4">
                <span
                  class="text-nowrap my-auto"
                  style="min-width: 140px"
                  >URL</span
                >
                <input
                  class="form-input m-0"
                  type="text"
                  v-model="valuePrice.externalPaymentRedirectUrlIdentifier"
                  style="margin: 0"
                  readonly="true"
                  placeholder="登録または更新後に表示されます。"
                  :disabled="!checkCopyUrl(valuePrice.externalPaymentRedirectUrlIdentifier)"

                />
                <CButton
                type="button"
                class="btn mx-2 px-4 text-nowrap btn-other"
                color="info"
                square
                variant="outline"
                size="sm"
                :disabled="!checkCopyUrl(valuePrice.externalPaymentRedirectUrlIdentifier)"
                v-on:click="copyURL(valuePrice.externalPaymentRedirectUrlIdentifier)"
                >
                  コピー
                </CButton>
              </div>
            </div>
            <div v-if="
              !(
                valuePrice.defaultPayment.indexOf(3) !== -1
                && valuePrice.defaultPayment.length === 1
                && valuePrice.externalPaymentUrlSkip === 1
              )
              "
              class="w-100"
              >
              <label class="col-sm-12 font-weight-bold cp-label"
                >オプション：ユーザー入力情報</label
              >
              <div class="w-100 cp-4">
                <b-form-group class="mb-0" v-slot="{ ariaDescribedby1 }">
                  <div class="d-flex">
                    <div class="mx-1 text-center">
                      <b-form-radio
                        v-model="content.user_input"
                        class="mr-3 d-none"
                        id="radio_3"
                        :aria-describedby="ariaDescribedby1"
                        name="input-radios"
                        :value="0"
                        >通常</b-form-radio
                      >
                      <label
                        for="radio_3"
                        :class="
                          content.user_input == 0
                            ? 'btn-share-freesize-active py-2 px-4'
                            : 'btn-share-freesize py-2 px-4'
                        "
                      >
                        通常
                      </label>
                    </div>
                    <div class="mx-1 text-center">
                      <b-form-radio
                        v-model="content.user_input"
                        class="mx-3 d-none"
                        id="radio_4"
                        :aria-describedby="ariaDescribedby1"
                        name="input-radios"
                        :value="1"
                        >詳細</b-form-radio
                      >
                      <label
                        for="radio_4"
                        :class="
                          content.user_input == 1
                            ? 'btn-share-freesize-active py-2 px-4'
                            : 'btn-share-freesize py-2 px-4'
                        "
                      >
                        詳細
                      </label>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >オプション：購入後のユーザーに付与する情報</label
              >
              <div class="w-100 d-flex cp-4">
                <span class="text-nowrap my-auto px-2" style="min-width: 100px"
                  >コンテンツ</span
                >
                <div
                  class="d-flex justify-content-between align-items-center add-slag w-100"
                >
                  <div class="custom-select-2 w-100 custome-multi-slag">
                    <multiselect
                      v-model="valuePrice.baseContent"
                      tag-placeholder="購入後、付与するコンテンツを追加できます。"
                      placeholder="購入後、付与するコンテンツを追加できます。"
                      label="text"
                      track-by="text"
                      :options="listContentOptions"
                      :multiple="true"
                      :taggable="false"
                      :allow-empty="true"
                      :max-height="200"
                      :showNoResults="false"
                      tag-position="bottom"
                      select-label="入力して選択する"
                      selected-label="選択された"
                      deselect-label="入力して削除する"
                      class="m-0"
                    >
                      <template v-slot:no-options> リストは空です </template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="w-100 d-flex cp-4">
                <span class="text-nowrap my-auto px-2" style="min-width: 100px"
                  >スラグ</span
                >
                <div
                  class="d-flex justify-content-between align-items-center add-slag w-100"
                >
                  <div class="custom-select-2 w-100 custome-multi-slag">
                    <multiselect
                      v-model="valuePrice.baseSlag"
                      tag-placeholder="購入後、付与するスラグを追加できます。"
                      placeholder="購入後、付与するスラグを追加できます。"
                      label="slag_name"
                      track-by="slag_name"
                      :options="listSlagOptions"
                      :multiple="true"
                      :taggable="false"
                      :showNoResults="false"
                      :allow-empty="true"
                      :max-height="200"
                      select-label="入力して選択する"
                      selected-label="選択された"
                      class="m-0"
                      deselect-label="入力して削除する"
                    >
                      <template v-slot:no-options> リストは空です </template>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >オプション：重複購入設定</label
              >
              <div class="w-100 cp-4">
                <div class="w-100 d-flex align-items-center">
                  <b-form-group v-slot="{}" style="margin: 0">
                    <b-form-checkbox
                      v-model="valuePrice.isBuyDuplicate"
                      id="buy-duplicate"
                      name="buy-duplicate"
                      value="1"
                      unchecked-value="0"
                      style="min-width: 170px; margin-right: 50px"
                      >重複購入を禁止する</b-form-checkbox
                    >
                  </b-form-group>
                  <input
                    class="form-input m-0"
                    type="text"
                    v-model="valuePrice.urlDuplicate"
                    style="margin: 0"
                  />
                </div>
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >オプション：税</label
              >
              <div class="w-100 cp-4 d-flex align-items-center">
                <input
                  class="form-input m-0"
                  type="text"
                  v-model="valuePrice.tax"
                  style="margin: 0; width: 200px"
                />&nbsp;&nbsp;%
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >オプション：送料</label
              >
              <div class="w-100 cp-4 d-flex align-items-center">
                <input
                  class="form-input m-0"
                  type="text"
                  v-model="valuePrice.fee"
                  style="margin: 0; width: 200px"
                />&nbsp;&nbsp;円
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >オプション：詳細説明</label
              >
              <div class="w-100 cp-4 d-flex align-items-center">
                <CTextarea
                  v-model="valuePrice.explan"
                  rows="10"
                  class="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :hidden="typeSetting === 3 ? false : true">
        <div class="w-100 row-input">
          <div class="w-100">
            <label class="col-sm-12 font-weight-bold cp-label"
              >ショップ表示設定</label
            >
          </div>
          <div class="w-100 cp-4">
            <b-form-group class="mb-0" v-slot="{ ariaDescribedby }">
              <div class="d-flex">
                <div class="mx-1 text-center">
                  <b-form-radio
                    v-model="content.isPublic"
                    class="mr-3 d-none"
                    id="radio_1"
                    :aria-describedby="ariaDescribedby"
                    name="public-radios"
                    :value="0"
                    >非公開</b-form-radio
                  >
                  <label
                    for="radio_1"
                    style="width: 130px"
                    :class="
                      content.isPublic == 0
                        ? 'btn-active-radio py-2 px-4'
                        : 'btn-inactive-radio py-2 px-4'
                    "
                  >
                    非公開
                  </label>
                </div>
                <div class="mx-1 text-center">
                  <b-form-radio
                    v-model="content.isPublic"
                    class="mx-3 d-none"
                    id="radio_2"
                    :aria-describedby="ariaDescribedby"
                    name="public-radios"
                    :value="1"
                    >公開</b-form-radio
                  >
                  <label
                    for="radio_2"
                    style="width: 130px"
                    :class="
                      content.isPublic == 1
                        ? 'btn-active-radio py-2 px-4'
                        : 'btn-inactive-radio py-2 px-4'
                    "
                  >
                    公開
                  </label>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="w-100 row-input">
          <div class="w-100">
            <label class="col-sm-12 font-weight-bold cp-label"
              >ショップ画像</label
            >
          </div>
          <div class="w-100 cp-4">
            <div class="w-100 row-input">
              <div class="row pl-0">
                <div class="col-sm-6 my-auto">
                  <label
                    for="fileInput"
                    class="btn label-input-file btn-success btn-color"
                    >写真選択</label
                  >
                </div>
                <div class="col-sm-6">
                  <div class="">
                    <input
                      id="fileInput"
                      class="d-none"
                      accept="image/jpg, image/png, image/jpeg"
                      @change="uploadImg($event)"
                      type="file"
                    />
                    <div v-if="previews.src" class="float-md-right">
                      <div class="show-preview float-md-right my-auto">
                        <img
                          v-if="content.imgCheck"
                          :src="content.imgCheck"
                          width="100%"
                          height="100%"
                          style="object-fit: contain"
                        />
                      </div>
                    </div>
                    <div
                      v-if="content.imagePre"
                      class="show-preview float-right"
                    >
                      <img
                        class="preview-img"
                        width="100%"
                        height="100%"
                        style="object-fit: contain"
                        :src="content.imagePre"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="content.fileName">
              <div class="justify-content-center text-center">
                <p class="group-title">画像編集ツール</p>
              </div>
              <div class="cropper-component">
                <cropper
                  class="cropper"
                  :src="option.img"
                  :debounce="false"
                  :stencil-props="{
                    aspectRatio: 1 / 1,
                  }"
                  @change="realTime"
                ></cropper>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-left: 0px; margin-right: 0px">
          <label class="col-sm-12 font-weight-bold cp-label">ショップLP</label>
          <div class="w-100 p-4">
            <!-- <transition-group> -->
            <div
              v-for="(value, index) in content.listDisplay"
              :key="value.value"
            >
              <div class="d-flex row-input">
                <div class="cus-bt-mov">
                  <b-button
                    v-if="content.listDisplay.length > 1"
                    variant="dark"
                    :disabled="isDisabled"
                    class="rounded-circle custome-btn-sub mt-0 bt-sub"
                    style="
                      border-radius: 50% !important;
                      margin-left: 5px;
                      background: #333;
                    "
                    v-on:click="downSort(index)"
                    ><b-icon icon="arrow-down-circle" scale="2"
                  /></b-button>
                  <b-button
                    v-if="content.listDisplay.length > 1"
                    variant="dark"
                    :disabled="isDisabled"
                    class="rounded-circle custome-btn-sub mt-0 bt-sub"
                    style="border-radius: 50% !important; background: #333"
                    v-on:click="upSort(index)"
                    ><b-icon icon="arrow-up-circle" scale="2"
                  /></b-button>
                </div>
                <div class="d-flex mt-3" v-if="value.value < 5">
                  <b-button
                    @click="removeTypeDisplay(index)"
                    style="width: 38px; margin-right: 10px; color: white"
                    class="bg-00b797"
                  >
                    <font-awesome-icon
                      class="sidebar-icon"
                      :icon="['fas', 'minus']"
                    />
                  </b-button>
                  <b-button class="text-left" style="width: 500px">
                    {{ value.text }}
                  </b-button>
                </div>
                <div class="mt-3 d-flex align-item-center" v-else>
                  <div v-if="!value.text" class="d-flex">
                    <b-button
                      @click="removeTypeDisplay(index)"
                      style="height: 35px; margin-right: 10px; color: white"
                      class="bg-00b797"
                    >
                      <font-awesome-icon
                        class="sidebar-icon"
                        :icon="['fas', 'minus']"
                      />
                    </b-button>
                    <select class="select-display" size="4">
                      <option
                        v-for="(item, indexItem) in listDisplay"
                        :key="indexItem"
                        @click="selectOption(item.text, value.value)"
                      >
                        {{ item.text }}
                      </option>
                    </select>
                  </div>
                  <div v-else-if="value.text == '自由記入'" class="d-flex">
                    <b-button
                      @click="removeTypeDisplay(index, value.value)"
                      style="height: 35px; margin-right: 10px; color: white"
                      class="bg-00b797"
                    >
                      <font-awesome-icon
                        class="sidebar-icon"
                        :icon="['fas', 'minus']"
                      />
                    </b-button>
                    <div style="width: 500px">
                      <input
                        class="form-input m-0"
                        type="text"
                        v-model="value.title"
                        style="margin: 0"
                        placeholder="タイトル"
                      />
                      <button
                        class="btn-collapse position-absolute p-0 float-right"
                        :class="!value.show ? 'close-collapse' : ''"
                        style="
                          width: 0px;
                          background-color: #fff !important;
                          border: none;
                          margin-top: 10px;
                          outline: 0;
                        "
                        v-b-toggle="'collapse' + index"
                        v-on:click="focusEditor(value.value)"
                        @click="value.show = !value.show"
                      ></button>
                    </div>
                  </div>
                  <div class="d-flex align-items-center" v-else>
                    <b-button
                      @click="removeTypeDisplay(index)"
                      style="height: 35px; margin-right: 10px; color: white"
                      class="bg-00b797"
                    >
                      <font-awesome-icon
                        class="sidebar-icon"
                        :icon="['fas', 'minus']"
                      />
                    </b-button>
                    <b-button class="text-left" style="width: 500px">
                      {{ value.text }}
                    </b-button>
                  </div>
                </div>
              </div>
              <div
                class="d-flex"
                style="
                  width: fit-content;
                  margin-left: auto;
                  margin-right: 31px;
                "
                v-if="value.text == '自由記入'"
              >
                <div
                  v-on:click="undo(index)"
                  style="cursor: pointer; margin-right: 10px; margin-top: 3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-arrow-90deg-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"
                    />
                  </svg>
                </div>
                <div
                  v-on:click="redo(index)"
                  style="cursor: pointer; margin-right: 15px; margin-top: 3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-arrow-90deg-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4z"
                    />
                  </svg>
                </div>
                <div v-on:click="copyPage(index)" style="cursor: pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-files"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"
                    />
                  </svg>
                </div>
                <div
                  v-on:click="pastePage(index)"
                  class="ml-2"
                  style="cursor: pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-clipboard"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                    />
                    <path
                      d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="position-relative w-100 cpx-4 mx-2 mt-2 editor-collapse"
                :class="'collapse' + index"
                v-if="value.text == '自由記入'"
                :hidden="value.show === true ? false : true"
              >
                <editor
                  v-bind:id="`editor-${index}`"
                  @dataEditor="setValueLP($event, index)"
                  @onChange="editorChange($event)"
                  v-bind:isEdit="true"
                  v-bind:dataEditor="
                    value.content ? JSON.stringify(value.content) : ''
                  "
                  class="mb-4"
                ></editor>
              </div>
            </div>
            <!-- </transition-group> -->
            <div class="d-flex mx-3 mt-3">
              <b-button
                style="
                  border-radius: 50% !important;
                  height: 38.25px !important;
                  margin-right: 10px;
                "
                class="bg-00b797 text-white"
                @click="addEditor()"
              >
                <font-awesome-icon
                  class="sidebar-icon"
                  :icon="['fas', 'plus']"
                />
              </b-button>
            </div>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >表示条件設定</label
          >
          <div class="w-100 cp-4">
            <div class="btn-toggle-active">
              <input
                v-model="content.typeConditionSlag"
                class="m-0"
                type="radio"
                name="typeConditionSlag"
                id="typeConditionSlag-1"
                :value="1"
              />
              <label
                for="typeConditionSlag-1"
                :class="
                  content.typeConditionSlag === 1
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >コンテンツ所持設定</label
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="content.typeConditionSlag"
                class="m-0"
                type="radio"
                name="typeConditionSlag"
                id="typeConditionSlag-2"
                :value="2"
              />
              <label
                for="typeConditionSlag-2"
                :class="
                  content.typeConditionSlag === 2
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >スラグ所持設定</label
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="content.typeConditionSlag"
                class="m-0"
                type="radio"
                name="typeConditionSlag"
                id="typeConditionSlag-3"
                :value="3"
              />
              <label
                for="typeConditionSlag-3"
                :class="
                  content.typeConditionSlag === 3
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >全員に表示</label
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="content.typeConditionSlag"
                class="m-0"
                type="radio"
                name="typeConditionSlag"
                id="typeConditionSlag-4"
                :value="4"
              />
              <label
                for="typeConditionSlag-4"
                :class="
                  content.typeConditionSlag === 4
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                >全員に非表示</label
              >
            </div>
          </div>
        </div>
        <div
          v-if="content.typeConditionSlag == constant_display['SET_CONTENT']"
        >
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">基本設定</label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.content_basic"
                  tag-placeholder="コンテンツを選択してください。"
                  placeholder="コンテンツを選択してください。"
                  label="text"
                  track-by="text"
                  :options="listContentOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >and1（基本設定とand1両方のコンテンツを持つユーザーだけに表示）
            </label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.content_basic1"
                  :disabled="content.content_basic.length > 0 ? false : true"
                  tag-placeholder="コンテンツを選択してください。"
                  placeholder="コンテンツを選択してください。"
                  label="text"
                  track-by="text"
                  :options="listContentOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >and2（基本設定＋and1＋and2をコンテンツを持つユーザーだけに表示）
            </label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.content_basic2"
                  :disabled="
                    content.content_basic.length > 0 &&
                    content.content_basic1.length > 0
                      ? false
                      : true
                  "
                  tag-placeholder="コンテンツを選択してください。"
                  placeholder="コンテンツを選択してください。"
                  label="text"
                  track-by="text"
                  :options="listContentOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >表示条件を満たしていない場合のリダイレクト先URL</label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.urlNoConditionDisplay"
              />
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >非表示（以下のコンテンツを持つユーザーは非表示）
            </label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.content_hidden"
                  tag-placeholder="コンテンツを選択してください。"
                  placeholder="コンテンツを選択してください。"
                  label="text"
                  track-by="text"
                  :options="listContentOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <!-- <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >非表示条件に該当する場合のリダイレクト先URL</label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.urlConditionNoDisplay"
              />
            </div>
          </div> -->
        </div>
        <div v-if="content.typeConditionSlag == constant_display['SET_SLAG']">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">基本設定</label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.slags_basic"
                  tag-placeholder="カテゴリを選択してください"
                  placeholder="カテゴリを選択してください"
                  label="slag_name"
                  track-by="slag_name"
                  :options="listSlagOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >and1（基本設定とand1両方のスラグを持つユーザーだけに表示）
            </label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.slags_basic1"
                  :disabled="content.slags_basic.length > 0 ? false : true"
                  tag-placeholder="カテゴリを選択してください"
                  placeholder="カテゴリを選択してください"
                  label="slag_name"
                  track-by="slag_name"
                  :options="listSlagOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >and2（基本設定＋and1＋and2をスラグを持つユーザーだけに表示
            </label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.slags_basic2"
                  :disabled="
                    content.slags_basic.length > 0 &&
                    content.slags_basic1.length > 0
                      ? false
                      : true
                  "
                  tag-placeholder="カテゴリを選択してください"
                  placeholder="カテゴリを選択してください"
                  label="slag_name"
                  track-by="slag_name"
                  :options="listSlagOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >表示条件を満たしていない場合のリダイレクト先URL</label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.urlNoConditionDisplay"
              />
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >非表示（以下のスラグを持つユーザーは非表示）
            </label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.slag_hidden"
                  tag-placeholder="カテゴリを選択してください"
                  placeholder="カテゴリを選択してください"
                  label="slag_name"
                  track-by="slag_name"
                  :options="listSlagOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <!-- <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >非表示条件に該当する場合のリダイレクト先URL</label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.urlConditionNoDisplay"
              />
            </div>
          </div> -->
        </div>
        <div v-if="content.typeConditionSlag != 2"></div>
        <div v-if="content.typeConditionSlag != 3">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >非表示条件に該当する場合のリダイレクト先URL</label
            >
            <div class="w-100 cp-4">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.urlConditionNoDisplay"
                style="margin: 0"
              />
            </div>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >ショップカテゴリー</label
          >
          <div class="w-100 cp-4">
            <multiselect
              v-model="content.list_category"
              tag-placeholder="カテゴリを選択してください。"
              placeholder="カテゴリを選択してください"
              label="text"
              track-by="text"
              :options="listCategoryOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
              @tag="addTag"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >ショップリダイレクト先</label
          >
          <div class="w-100 cp-4">
            <div class="w-100 d-flex align-items-center">
              <b-form-group v-slot="{}" style="margin: 0">
                <b-form-checkbox
                  v-model="content.isUrlClickContent"
                  id="click-content"
                  name="click-content"
                  class="form-checbox-n"
                  value="1"
                  unchecked-value="0"
                  style="min-width: 300px"
                  >指定URLへリダイレクトする</b-form-checkbox
                >
              </b-form-group>
              <input
                class="form-input m-0"
                type="text"
                v-model="content.urlClickItemContent"
                style="margin: 0"
              />
            </div>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label cp-label"
            >タグ（類似条件設定）
          </label>
          <div class="cp-4 col-12">
            <input class="form-input m-0" type="text" v-model="content.tags" />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label cp-label"
            >登録させるメルマガフォーム
          </label>
          <div class="cp-4 col-12 col-md-6">
            <multiselect
              v-model="content.mailMega"
              tag-placeholder=""
              placeholder=""
              label="text"
              track-by="value"
              :options="listMailMegas"
              :multiple="true"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template slot="noOptions"> リストは空です </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div v-if="typeSetting === 4">
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label">購入時設定</label>
          <div class="w-100 p-3">
            <div class="btn-toggle-active">
              <input
                v-model="content.isVote"
                class="m-0"
                type="radio"
                name="typeVote"
                value="0"
              />
              <b-button class="btn-common btn-checkbox-active"
                >通常購入</b-button
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="content.isVote"
                class="m-0"
                type="radio"
                name="typeVote"
                value="1"
              />
              <b-button class="btn-common btn-checkbox-active px-4"
                >投票</b-button
              >
            </div>
          </div>
        </div>
        <div class="row row-input" v-if="content.isVote == 1">
          <label class="col-sm-12 font-weight-bold cp-label">表示投票数</label>
          <div class="w-100 p-3">
            <input
              class="form-input m-0"
              type="text"
              v-model="content.countVote"
              style="margin: 0"
              disabled
            />
          </div>
        </div>
        <div class="row row-input" v-if="content.isVote == 1">
          <label class="col-sm-12 font-weight-bold cp-label"
            >現在の投票数</label
          >
          <div class="w-100 d-flex p-3">
            <span class="text-nowrap my-auto px-2" style="min-width: 100px"
              >付与スラグ</span
            >
            <div
              class="d-flex justify-content-between align-items-center add-slag w-100"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.slagIdVote"
                  tag-placeholder="購入付与スラグを追加してください"
                  placeholder="購入付与スラグを追加してください"
                  label="slag_name_en"
                  track-by="slag_name_en"
                  :options="listSlagOptions"
                  :multiple="true"
                  :taggable="false"
                  :showNoResults="false"
                  :allow-empty="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template v-slot:no-options> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="w-100 d-flex p-3">
            <span class="text-nowrap my-auto px-2" style="min-width: 100px"
              >付与イント</span
            >
            <input
              class="form-input m-0"
              type="text"
              v-model="content.pointVote"
              style="margin: 0"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label">追加設定</label>
          <div class="w-100 p-3">
            <div class="btn-toggle-active">
              <input
                v-model="content.typeContent"
                class="m-0"
                type="radio"
                name="typeContent"
                value="0"
              />
              <b-button class="btn-common btn-checkbox-active"
                >アンケート設定</b-button
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="content.typeContent"
                class="m-0"
                type="radio"
                name="typeContent"
                value="1"
              />
              <b-button class="btn-common btn-checkbox-active px-4"
                >ワンタイムオファー設定</b-button
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="content.typeContent"
                class="m-0"
                type="radio"
                name="typeContent"
                value="2"
              />
              <b-button class="btn-common btn-checkbox-active"
                >カウントダウン設定</b-button
              >
            </div>
          </div>
        </div>
        <div class="row row-input" v-if="content.typeContent == 0">
          <label class="col-sm-12 font-weight-bold cp-label"
            >アンケート選択</label
          >
          <div class="w-100 d-flex p-3">
            <div
              class="d-flex justify-content-between align-items-center add-slag w-100"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.surveyId"
                  tag-placeholder="購入付与スラグを追加してください"
                  placeholder="購入付与スラグを追加してください"
                  label="text"
                  track-by="text"
                  :options="listSurveys"
                  :multiple="false"
                  :taggable="false"
                  :showNoResults="false"
                  :allow-empty="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template v-slot:no-options> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-input" v-if="content.typeContent == 2">
          <label class="col-sm-12 font-weight-bold cp-label">パターン</label>
          <div class="w-100 p-3">
            <div class="btn-toggle-active">
              <input
                v-model="content.typeCountdown"
                class="m-0"
                type="radio"
                name="typeCountdown"
                value="1"
              />
              <b-button class="btn-common btn-checkbox-active"
                >日時指定方式</b-button
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="content.typeCountdown"
                class="m-0"
                type="radio"
                name="typeCountdown"
                value="2"
              />
              <b-button class="btn-common btn-checkbox-active px-4"
                >アクセス後カウント方式</b-button
              >
            </div>
          </div>
          <div class="w-100 d-flex p-3">
            <span class="text-nowrap my-auto px-2" style="min-width: 150px"
              >カウントダウン名前</span
            >
            <input
              class="form-input m-0"
              type="text"
              v-model="content.nameCountdown"
              style="margin: 0"
            />
          </div>
          <div class="w-100 d-flex p-3" v-if="content.typeCountdown == 1">
            <span class="text-nowrap my-auto px-2" style="min-width: 150px"
              >カウントダウン時間</span
            >
            <date-picker
              name="date"
              v-model="content.timeCountdown"
              :config="optionsDate"
              autocomplete="off"
              style="margin: 0"
              placeholder="日付を選択"
            ></date-picker>
          </div>
          <div class="w-100 d-flex p-3 align-items-center" v-else>
            <span class="text-nowrap my-auto px-2" style="min-width: 150px"
              >カウントダウン時間</span
            >
            <span style="width: 50px">日</span>
            <input
              class="form-input m-0"
              type="text"
              v-model="content.numDay"
              style="margin: 0; width: 100px"
            />
            <span style="width: 100px; text-align: center">時</span>
            <input
              class="form-input m-0"
              type="text"
              v-model="content.numHour"
              style="margin: 0; width: 100px"
            />
          </div>
          <div class="w-100 d-flex p-3">
            <span class="text-nowrap my-auto px-2" style="min-width: 150px"
              >リダイレクトURL</span
            >
            <input
              class="form-input m-0"
              type="text"
              v-model="content.urlRedirect"
              style="margin: 0"
            />
          </div>
        </div>
        <div class="d-flex justify-content-center py-3" v-if="isEdit">
          <b-button
            v-on:click.prevent="saveContentType()"
            class="btn-color sale-btn"
            type="submit"
            :disabled="isLoadingCreateContent"
          >
            <b-spinner
              v-if="isLoadingCreateContent"
              small
              class="mb-1"
            ></b-spinner>
            更新する
          </b-button>
        </div>
      </div>
      <div class="d-flex justify-content-center py-3">
        <b-button
          class="btn-color sale-btn"
          type="submit"
          v-on:click.prevent="saveContent()"
          :disabled="isLoadingCreateContent"
        >
          <b-spinner v-if="isLoadingCreateContent" small></b-spinner>
          更新する
        </b-button>
      </div>
    </div>
    <div>
      <b-modal id="modal-confirm-content" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>他のページに移動しても問題ありませんか？</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-accept-confirm"
            block
            :disabled="isLoadingConfirm"
            v-on:click.prevent="goToCreateContent()"
          >
            <b-spinner v-if="isLoadingConfirm" small></b-spinner>
            はい
          </b-button>
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            :disabled="isLoadingConfirm"
            @click="$bvModal.hide('modal-confirm-content')"
          >
            いいえ
          </b-button>
        </div>
      </b-modal>
      <b-modal
        v-model="showPopupSetCondition"
        ref="setting-condition"
        size="lg"
        title="ユーザーが所持する情報を設定"
        hide-footer
      >
        <div
          class="ml-5 mt-2"
          v-for="(val, index) in conditions"
          v-bind:key="index"
        >
          <div class="d-flex">
            <div>
              <b-form-radio-group
                v-model="val.typeConditon"
                :options="typeConditons"
                :name="'plain' + index"
                @change="changeTypeCondition(val)"
                plain
                stacked
              ></b-form-radio-group>
            </div>
            <div class="ml-4 mr-4" style="width: 60%">
              <div
                class="custom-select-2 w-100 custome-multi-slag m-0"
                v-if="val.typeConditon == 1"
              >
                <multiselect
                  v-model="val.id"
                  tag-placeholder="コンテンツ追加"
                  placeholder="コンテンツ追加"
                  label="text"
                  track-by="value"
                  :options="listContentOptions"
                  :multiple="true"
                  :taggable="false"
                  :showNoResults="false"
                  select-label="入力して選択する"
                  deselect-label="入力して削除する"
                  selected-label="選択された"
                >
                  <template v-slot:no-options> リストは空です </template>
                </multiselect>
              </div>
              <div class="custom-select-2 w-100 custome-multi-slag m-0" v-else>
                <multiselect
                  v-model="val.id"
                  tag-placeholder="スラグ追加"
                  placeholder="スラグ追加"
                  label="slag_name"
                  track-by="slag_id"
                  :options="listSlagOptions"
                  :multiple="true"
                  :taggable="false"
                  :showNoResults="false"
                  :select-label="'入力して選択する'"
                  :deselect-label="'入力して削除する'"
                  :selected-label="'選択された'"
                >
                  <template v-slot:no-options> リストは空です </template>
                </multiselect>
              </div>
            </div>
            <div>
              <b-form-radio-group
                v-model="val.ownershipCondition"
                :options="ownershipConditions"
                :name="'plain1' + index"
                plain
                stacked
              ></b-form-radio-group>
            </div>
            <b-button
              v-if="conditions.length > 1"
              variant="dark"
              class="rounded-circle custome-btn-sub float-right mt-0 ml-4 bt-sub"
              style="border-radius: 50% !important"
              v-on:click="subCondition(index)"
              ><b-icon icon="dash" scale="2"
            /></b-button>
          </div>
          <div class="mt-3">
            <b-button class="mr-4 add-btn" v-on:click="addCondition(index)">
              and設定追加
            </b-button>
          </div>
        </div>

        <div class="d-flex mt-5 justify-content-center">
          <b-button @click="saveCondition" class="mr-4 btn-color sale-btn">
            <span>設定</span>
          </b-button>
          <b-button
            class="mr-4 back-btn"
            v-on:click="showPopupSetCondition = false"
          >
            閉じる
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import { Constants } from "../../utils/constants";
// import draggable from "vuedraggable";
// import { VueEditor } from "vue2-editor";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
import { v4 as uuidv4 } from "uuid";
import Editor from "../../components/editor/index.vue";
import { eventBus } from "../../main";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});

export default {
  name: "Content Sale",
  components: {
    Editor,
    Cropper,
  },
  data() {
    return {
      isLoadingConfirm: false,
      title: "",
      author_id: "",
      description: "",
      isLoading: false,
      image: null,
      page: [],
      listSlagOptions: [],
      listCategoryOptions: [],
      listAuthorOptions: [],
      chapter: [],
      page_title: "",
      viewBtnAddCover: true,
      listContentOptions: [],
      previewImage: null,
      category_name: "",
      category_code: "",
      first_name: "",
      last_name: "",
      email: "",
      descriptionAuthor: "",
      avatar: null,
      imagePre: null,
      filename: "",
      nameFile: "",
      checkImg: false,
      isCreateCat: false,
      urlImage: null,
      type_sale: 0,
      listActionType: [
        { value: 1, text: "日" },
        { text: "週", value: 2 },
        { text: "月", value: 3 },
        { text: "年", value: 4 },
      ],
      listPrice: [{ value: 1, text: "価格1", plan: "" }],
      listSetting: [
        { value: 1, text: "オファー種類" },
        { value: 2, text: "価格設定" },
        { value: 3, text: "ショップ設定" },
        // { value: 4, text: "オファー設定" },
        // { value: 5, text: "その他表示設定" },
      ],
      listSetting3: [
        { value: 1, text: "オファー種類" },
        { value: 2, text: "価格設定" },
      ],
      listDisplay: [
        { value: 0, text: "商品画像とタイトル表示" },
        { value: 1, text: "目次表示" },
        // { value: 2, text: "紹介ビデオ" },
        { value: 3, text: "制作者情報" },
        { value: 4, text: "自由記入" },
      ],
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      optionsDatePreSale: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      bill_internal: 1,
      isSelected: 1,
      isSelectedPrice: 0,
      text_change: "日",
      initial_cost: 0,
      trial_period: 0,
      add_bill: 0,
      billing_count: 0,
      type_sell: 0,
      listSurveys: [],
      optionsDefault_payment: [
        { text: "Paypalのみ", value: [1] },
        { text: "Stripeのみ", value: [0] },
        { text: "Paypal＋Stripeのみ", value: [0, 1] },
        { text: "Paypal＋銀行振込", value: [1, 2] },
        { text: "Stripe＋銀行振込", value: [0, 2] },
        { text: "銀行振込のみ", value: [2] },
        { text: "外部決済のみ", value: [3] },
        { text: "外部決済＋銀行振込", value: [2, 3] },
      ],
      optionsSubscriptionDefault_payment: [
        { text: "Paypalのみ", value: [1] },
        { text: "Stripeのみ", value: [0] },
        { text: "Paypal＋Stripeのみ", value: [0, 1] },
      ],
      // optionsQuickDefaultPayment: [
      //   { text: "Paypalのみ", value: [1] },
      //   { text: "Stripeのみ", value: [0] },
      // ],
      optionsPayment: [
        { text: "クレジットカード", value: 0 },
        { text: "paypal", value: 1 },
      ],
      optionsMinInputPrice: [
        { text: "Paypalのみ", value: [1] },
        { text: "Stripeのみ", value: [0] },
        { text: "Paypal＋Stripeのみ", value: [0, 1] },
        { text: "Paypal＋銀行振込", value: [1, 2] },
        { text: "Stripe＋銀行振込", value: [0, 2] },
        { text: "銀行振込のみ", value: [2] },
      ],
      // 外部決済URL方式の選択項目(Step5:入力フォームスキップ有無)
      externalPaymentUrlSkip: [
        {
          text: Constants.EXTERNAL_PAYMENT_URL_SKIP_LIST[Constants.EXTERNAL_PAYMENT_URL_SKIP.non_skip],
          value: Constants.EXTERNAL_PAYMENT_URL_SKIP.non_skip,
        },
        {
          text: Constants.EXTERNAL_PAYMENT_URL_SKIP_LIST[Constants.EXTERNAL_PAYMENT_URL_SKIP.skip],
          value: Constants.EXTERNAL_PAYMENT_URL_SKIP.skip,
        },
      ],
      // 外部決済URL方式の選択項目(Step6:認証方式)
      externalPaymentAutoApproval: [
        {
          text: Constants.EXTERNAL_PAYMENT_APPROVAL_TYPE_LIST[Constants.EXTERNAL_PAYMENT_APPROVAL_TYPE.manual],
          value: Constants.EXTERNAL_PAYMENT_APPROVAL_TYPE.manual,
        },
        {
          text: Constants.EXTERNAL_PAYMENT_APPROVAL_TYPE_LIST[Constants.EXTERNAL_PAYMENT_APPROVAL_TYPE.auto],
          value: Constants.EXTERNAL_PAYMENT_APPROVAL_TYPE.auto,
        },
        {
          text: Constants.EXTERNAL_PAYMENT_APPROVAL_TYPE_LIST[Constants.EXTERNAL_PAYMENT_APPROVAL_TYPE.conditional_manual],
          value: Constants.EXTERNAL_PAYMENT_APPROVAL_TYPE.conditional_manual,
        },
      ],
      externalPaymentReferrerCheckType: [
        {
          text: Constants.EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE_LIST[Constants.EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE.perfect],
          value: Constants.EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE.perfect,
        },
        {
          text: Constants.EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE_LIST[Constants.EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE.prefix],
          value: Constants.EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE.prefix,
        },
        {
          text: Constants.EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE_LIST[Constants.EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE.partial],
          value: Constants.EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE.partial,
        },
      ],
      method_sell: {
        type_sell: 0,
        survey_id: "",
        content_type: 0,
        name_countdown: "",
        time_countdown: "",
        url_redirect: "",
        time_pubic: "",
        amount_vote: 0,
        point: 0,
        slag_type_1: [],
        pre_sale_amount: 0,
        type_countdown: 1,
        pre_sale_date_start: "",
        pre_sale_date_end: "",
        numDay: "",
        numHour: "",
      },
      setting_detail: {
        LP_shop: "",
        url_no_condition: "",
        url_condition: "",
        url_redirect_click: "",
        message_price: "",
        setting_mail_mega: "",
        setting_show: 0,
        tax: 0,
        fee_ship: 0,
        input_user: 0,
        default_payment: [0],
        payment_month: [0],
        is_login: 0,
        show_pay: 0,
        slag_type: [],
        base_slag: 0,
      },
      pageSurvey: 1,
      typeSetting: 1,
      condition_setting: null,
      content: {
        product_type: "",
        product_title: "",
        author_id: null,
        list_category: [],
        price: [
          {
            type: "",
            product_title: "",
            sale_format: "0",
            product_price: "",
            give_point: 0,
            priceOverTime: "",
            initialCost: "",
            trialPeriod: "",
            billInternal: 1,
            addBill: "",
            textChange: "",
            billingCount: "",
            tax: "",
            fee: "",
            baseSlag: [],
            baseContent: [],
            textPrice: "",
            textButton: "",
            textBelowButton: "",
            defaultPayment: [1],
            defaultSubscription: [1],
            typeUrlPageThank: 0,
            urlPageThankCredit: "",
            urlPageThankTransfer: "",
            listSlagSetting: [
              {
                conditions: [
                  {
                    typeConditon: 1,
                    id: [],
                    ownershipCondition: 1,
                  },
                ],
                urlCreditCard: "",
                urlTransfer: "",
                urlExternalPayment: "",
              },
            ],
            isBuyDuplicate: 0,
            urlDuplicate: "",
            fromTimeRelease: "",
            toTimeRelease: "",
            explan: "",
            minInputPrice: "",
            defaultMinInputPrice: [],
            externalPaymentUrl: "",
            externalPaymentUrlSkip: Constants.EXTERNAL_PAYMENT_URL_SKIP.non_skip,
            externalPaymentAutoApproval: Constants.EXTERNAL_PAYMENT_APPROVAL_TYPE.manual,
            externalPaymentRedirectUrlIdentifier: "",
            externalPaymentReferrerCheckType: Constants.EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE.prefix,
            externalPaymentReferrerCheckText: "",
            externalPaymentThankUrl: "",
          },
        ],
        allowPreSale: 0,
        isPublic: 0,
        user_input: 0,
        fromTimeRelease: "",
        toTimeRelease: "",
        isPreSale: 0,
        isCondition: 0,
        urlNoConditionDisplay: "",
        urlConditionNoDisplay: "",
        isVote: 0,
        countVote: 0,
        slagIdVote: [],
        pointVote: 0,
        typeContent: 0,
        surveyId: [],
        typeCountdown: 1,
        timeCountdown: "",
        nameCountdown: "",
        numDay: 0,
        numHour: 0,
        urlRedirect: "",
        listDisplay: [
          { value: 0, text: "商品画像とタイトル表示" },
          { value: 1, text: "目次表示" },
          // { value: 2, text: "紹介ビデオ" },
          { value: 3, text: "制作者情報" },
          // { value: 4, text: "自由記入" },
        ],
        urlNoConditionDisplay1: "",
        urlConditionNoDisplay1: "",
        isUrlClickContent: false,
        urlClickItemContent: "",
        idMailMega: [],
        inputUser: [],
        urlVideo: "",
        urlPageThankCredit: "",
        urlPageThankTransfer: "",
        listSlagSetting: [{ id: "", urlCreditCard: "", urlTransfer: "" }],
        imagePre: "",
        fileName: "",
        img: "",
        imgCheck: "",
        typeConditionSlag: 3,
        textButton: "",
        textBelowButton: "",
        //
        slags_basic: [],
        slags_basic1: [],
        slags_basic2: [],
        slag_url_display: "",
        slag_hidden: [],
        slag_url_hidden: "",
        //
        content_basic: [],
        content_basic1: [],
        content_basic2: [],
        content_url_display: "",
        content_hidden: [],
        content_url_hidden: "",
        tags: "",
        mailMega: [],
      },
      pageMailMega: 1,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      dataTableContent: [],
      opDisable: [],
      dataOptionTitlePage: [],
      indexDataChapter: null,
      indexDataPage: null,
      content_condition_1: [],
      content_condition_2: [],
      content_condition_3: [],
      slag_condition_1: [],
      slag_condition_2: [],
      slag_condition_3: [],
      disabledConditionContent2: false,
      disabledConditionContent3: false,
      disabledConditionSlag2: false,
      disabledConditionSlag3: false,
      disabledConditionContentPage2: false,
      disabledConditionContentPage3: false,
      disabledConditionSlagPage2: false,
      disabledConditionSlagPage3: false,
      errorContentIdRequired: false,
      loadingContent: null,
      selectAdd: 1,
      dataObjectSlected: [],
      dataModalAddPage: [],
      indexChapterSelect: null,
      // dataListPage: [],
      isChecked: [],
      isShowFooter: true,
      showAddPage: null,
      objectSelected: [],
      isLoadingChapter: false,
      test: null,
      previews: {},
      option: {
        img: "",
        size: 1,
        full: false,
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 200,
        autoCropHeight: 200,
        centerBox: false,
        high: true,
        max: 99999,
      },
      fixedNumber: [1, 1],
      fixed: true,
      isCreateCategory: false,
      isEditor: false,
      isEdit: this.$route.params.id ? true : false,
      isLoadingCreateContent: false,
      constant_display: Constants.CONDITION_DISPLAY_CONTENT,
      listMailMegas: [],
      admin_info: JSON.parse(
        localStorage.getItem(
          Constants.ADMIN_USER_INFO +
            (this.$route.params.shopId
              ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
              : "_" + Constants.DOMAIN)
        )
      ),
      type_author: Constants.USER_TYPE_DEFAULT["author"],
      urlPageThankCreditDefault: null,
      urlPageThankTransferDefault: null,
      dataEditor: null,
      componentKey: 0,
      loadingUpload: false,
      check: 0,
      listDisplayCheck: null,
      checkFocusEditor: true,
      showPopupSetCondition: false,
      typeConditons: [
        { text: "コンテンツ", value: 1 },
        { text: "スラグ", value: 2 },
      ],
      ownershipConditions: [
        { text: "所持", value: 1 },
        { text: "未所持", value: 2 },
      ],
      conditions: [
        {
          typeConditon: 1,
          id: [],
          ownershipCondition: 1,
        },
      ],
      indexConditionEdit: null,
      contentPos: null,
      typeEvent: null,
      positionPaste: 0,
      isDisabled: false,
      allHistoryEditor: [],
      allIdUndo: [],
    };
  },
  mounted() {
    document.addEventListener("mousedown", function (e) {
      if (
        document.getElementById("editor-link") &&
        !document.getElementById("editor-link").contains(e.target)
      ) {
        let parent = document.getElementsByClassName(
          "ce-inline-toolbar--left-oriented"
        )[0];
        parent.style.removeProperty("opacity");
        parent.style.removeProperty("visibility");
      }
    });
  },
  async created() {
    this.loadingUpload = true;
    if (!this.$route.params.id) this.content.product_type = 0;
    const dataRequestContent = {
      page: 1,
      data: {
        shop_id: this.shop_id,
        limit: "all",
      },
    };
    const request = {
      shop_id: this.shop_id,
    };
    await this.getListAuthorInShop(request);
    await this.getALLlistSlag(request);
    await this.getListContents(dataRequestContent);
    await this.getSaleSettingAdmin(this.shop_id);
    const requestSurvey = {
      page: this.pageSurvey,
      data: {
        shop_id: this.shop_id,
      },
    };
    const formData = {
      page: this.pageMailMega,
      data: {
        shop_id: this.shop_id,
        limit: "all",
      },
    };
    await this.getListCategoriesInShop(request);
    await this.getListSurvey(requestSurvey);
    await this.getlistMailMega(formData);
    if (this.$route.params.id) {
      const reqData = {
        id: this.$route.params.id,
        shop_id: this.shop_id,
      };
      await this.getContentById(reqData);
    } else {
      if (this.admin_info.user_type == this.type_author) {
        this.content.author_id = this.admin_info.user_id;
      }
      await this.getListSettingShopAdmin({
        domain: Constants.DOMAIN,
        shop_id: this.$route.params.shopId,
      });
      await this.configContentThankPage();
    }
    this.loadingUpload = false;
  },
  watch: {
    contentById() {
      if (this.contentById) {
        let slagObj = {};
        this.listSlag.forEach((item) => {
          slagObj[item.id] = item.slag_name
            ? item.slag_name
            : item.slag_name_en;
        });

        let contentObj = {};
        this.listContents.forEach((item) => {
          contentObj[item.id] = item.title;
        });

        if (
          this.contentById.sale_other.column &&
          this.contentById.sale_other.column.length > 0
        ) {
          this.contentById.sale_other.column =
            this.contentById.sale_other.column.map((item) => {
              if (typeof item == "string") {
                return JSON.parse(item);
              } else {
                return item;
              }
            });
        }

        const sale_advance = this.contentById.sale_advance;
        const sale_basic = this.contentById.sale_basic;
        const sale_other = this.contentById.sale_other;

        this.title = this.contentById.title;
        this.description = this.contentById.description
          ? this.contentById.description
          : "";
        this.author_id = this.contentById.user_id;
        this.content.tags = this.contentById.tags;
        this.type_sale = 0;
        this.bill_internal = 1;
        this.text_change =
          this.bill_internal == 1
            ? "日"
            : this.bill_internal == 2
            ? "週間"
            : this.bill_internal == 3
            ? "ヶ月"
            : "年";
        this.survey_id = 1;
        //detail content
        this.content.product_type = this.contentById.product_type;
        this.content.product_title = this.contentById.title;

        //detail sale price
        if (sale_basic) {
          const column = this.contentById.sale_basic.column;
          const tempListSlagSetting = [
            {
              conditions: [
                {
                  typeConditon: 1,
                  id: [],
                  ownershipCondition: 1,
                },
              ],
              urlCreditCard: "",
              urlTransfer: "",
            },
          ];
          if (column && column.length > 0) {
            // check name content, slag. check content, slag has deleted
            column.forEach((element, index) => {
              let baseSlag = element.baseSlag;
              let baseContent = element.baseContent;
              let baseSlagReal = [];
              // slag
              if (baseSlag && baseSlag.length > 0 && this.listSlag.length > 0) {
                baseSlag.forEach((val) => {
                  for (let i = 0; i < this.listSlag.length; i++) {
                    let slag = this.listSlag[i];
                    if (slag.id == val.slag_id) {
                      baseSlagReal.push({
                        slag_id: slag.id,
                        slag_name_en: slag.slag_name_en,
                        slag_name: slag.slag_name
                          ? slag.slag_name
                          : slag.slag_name_en,
                      });
                      break;
                    }
                  }
                });
                this.contentById.sale_basic.column[index].baseSlag =
                  baseSlagReal;
              }
              // content
              if (
                baseContent &&
                baseContent.length > 0 &&
                this.listContents.length > 0
              ) {
                let baseContentReal = [];
                baseContent.forEach((val) => {
                  for (let i = 0; i < this.listContents.length; i++) {
                    let content = this.listContents[i];
                    if (content.id == val.value) {
                      baseContentReal.push({
                        value: content.id,
                        text: content.title,
                      });
                      break;
                    }
                  }
                });
                this.contentById.sale_basic.column[index].baseContent =
                  baseContentReal;
              }
            });

            this.content.price = column;
            this.isSelected = column[0].billInternal;
            this.content.price.forEach((element, index) => {
              if (
                element.listSlagSetting &&
                element.listSlagSetting.length > 0
              ) {
                element.listSlagSetting.forEach((val) => {
                  if (val.conditions && val.conditions.length > 0) {
                    val.conditions.forEach((condition) => {
                      if (condition.typeConditon == 1) {
                        condition.id.forEach((id) => {
                          id.text = contentObj[id.value];
                        });
                      } else {
                        condition.id.forEach((id) => {
                          id.slag_name = slagObj[id.slag_id];
                        });
                      }
                    });
                  }
                });
              }
              if (!element.listSlagSetting) {
                if (index == 0) {
                  element.listSlagSetting = [
                    {
                      id: "",
                      urlCreditCard: this.urlPageThankCreditDefault
                        ? this.urlPageThankCreditDefault
                        : "",
                      urlTransfer: this.urlPageThankTransferDefault
                        ? this.urlPageThankTransferDefault
                        : "",
                    },
                  ];
                } else element.listSlagSetting = tempListSlagSetting;
              }
              if (index > 0) {
                this.listPrice.push({
                  value: index + 1,
                  text: "価格" + (index + 1),
                });
              }
              element.defaultSubscription = JSON.parse(
                "[" + element.defaultSubscription + "]"
              );
              element.defaultPayment = JSON.parse(
                "[" + element.defaultPayment + "]"
              );
              // 外部決済戻りURLの対処
              if (element.externalPaymentRedirectUrlIdentifier) {
                  element.externalPaymentRedirectUrlIdentifier =
                    window.location.origin +
                      (this.$route.params.shopId ? "/shop/" + this.$route.params.shopId : "") +
                      "/" + Urls.EXTERNAL_PAYMENT_REDIRECT + "/" +
                      element.externalPaymentRedirectUrlIdentifier;
              } else {
                element.externalPaymentRedirectUrlIdentifier = "";
              }
              // 外部決済リファラURL
              if (typeof element.externalPaymentReferrerCheckType === 'undefined') {
                element.externalPaymentReferrerCheckType = Constants.EXTERNAL_PAYMENT_REFERRER_CHECK_TYPE.prefix;
              }
            });
          } else {
            this.configContentThankPage();
          }
        }
        this.configureContentPrice();

        //detail offer shop
        this.content.isPublic = sale_advance.shop_public;
        this.content.user_input = sale_other.user_input
          ? sale_other.user_input
          : 0;
        if (sale_other.column && sale_other.column.length > 0) {
          this.content.listDisplay = sale_other.column;
          if (
            this.content.listDisplay.filter(
              (item) => item.text == "自由記入"
            ) != -1
          ) {
            this.isEditor = true;
          }
          if (this.content.listDisplay.length > 0) {
            this.content.listDisplay.forEach((item) => {
              if (item.text == "自由記入") {
                let indexs = [];
                item.content.blocks.forEach((el, index) => {
                  if (el.type == "paragraph") {
                    let text = el.data.text;
                    let div = document.createElement("div");
                    div.innerHTML = text;
                    let children = this.getChildrent(div);
                    if (children.innerHTML.includes("<br>")) {
                      let innerHTML = children.innerHTML.split("<br>");
                      if (!innerHTML[0].replace(/&nbsp;/g, "")) {
                        innerHTML.splice(0, 1);
                        children.innerHTML = innerHTML.join("<br>");
                        el.data.text = div.innerHTML;
                      }
                      indexs.push(index);
                    }
                  }
                });
                indexs.forEach((i) => {
                  item.content.blocks.splice(i, 0, {
                    id: (Math.random() + 1).toString(36).substring(0, 10),
                    type: "paragraph",
                    data: {
                      text: "",
                      alignment: "left",
                      lineHeight: null,
                    },
                    tunes: {
                      anyTuneName: {
                        alignment: "left",
                      },
                    },
                  });
                });
              }
              this.allHistoryEditor.push([item.content]);
              this.allIdUndo.push(0);
            });
          }
        }
        this.content.author_id = this.contentById.user_id;
        this.content.tags = this.contentById.tags;
        if (
          this.contentById.categorys &&
          this.contentById.categorys.length > 0
        ) {
          this.content.list_category = this.contentById.categorys.map(
            (item) => ({
              value: item.id,
              text: item.category_name,
            })
          );
        }
        this.content.isUrlClickContent = sale_advance.checkbox_url_redirect;
        this.content.urlClickItemContent = sale_other.url_item_click_redirect
          ? sale_other.url_item_click_redirect
          : "";
        this.content.textBelowButton = sale_basic.message_below_button
          ? sale_basic.message_below_button
          : "";
        this.content.textButton = sale_basic.message_in_button
          ? sale_basic.message_in_button
          : "";
        this.content.typeConditionSlag = sale_advance.sale_format;
        if (
          sale_advance.sale_format ==
          Constants.CONDITION_DISPLAY_CONTENT["SET_CONTENT"]
        ) {
          if (
            sale_advance.content_id_1_adv &&
            sale_advance.content_id_1_adv.length > 0
          ) {
            this.content.content_basic = sale_advance.content_id_1_adv.map(
              (item) => ({
                value: item.id,
                text: item.title,
              })
            );
          }
          if (
            sale_advance.content_id_2_adv &&
            sale_advance.content_id_2_adv.length > 0
          ) {
            this.content.content_basic1 = sale_advance.content_id_2_adv.map(
              (item) => ({
                value: item.id,
                text: item.title,
              })
            );
          }
          if (
            sale_advance.content_id_3_adv &&
            sale_advance.content_id_3_adv.length > 0
          ) {
            this.content.content_basic2 = sale_advance.content_id_3_adv.map(
              (item) => ({
                value: item.id,
                text: item.title,
              })
            );
          }
          if (
            sale_advance.content_id_4_adv &&
            sale_advance.content_id_4_adv.length > 0
          ) {
            this.content.content_hidden = sale_advance.content_id_4_adv.map(
              (item) => ({
                value: item.id,
                text: item.title,
              })
            );
          }
        } else if (
          sale_advance.sale_format ==
          Constants.CONDITION_DISPLAY_CONTENT["SET_SLAG"]
        ) {
          if (
            sale_advance.slag_id_1_adv &&
            sale_advance.slag_id_1_adv.length > 0
          ) {
            this.content.slags_basic = sale_advance.slag_id_1_adv.map(
              (item) => ({
                slag_id: item.id,
                slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
              })
            );
          }
          if (
            sale_advance.slag_id_2_adv &&
            sale_advance.slag_id_2_adv.length > 0
          ) {
            this.content.slags_basic1 = sale_advance.slag_id_2_adv.map(
              (item) => ({
                slag_id: item.id,
                slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
              })
            );
          }
          if (
            sale_advance.slag_id_3_adv &&
            sale_advance.slag_id_3_adv.length > 0
          ) {
            this.content.slags_basic2 = sale_advance.slag_id_3_adv.map(
              (item) => ({
                slag_id: item.id,
                slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
              })
            );
          }
          if (
            sale_advance.slag_id_4_adv &&
            sale_advance.slag_id_4_adv.length > 0
          ) {
            this.content.slag_hidden = sale_advance.slag_id_4_adv.map(
              (item) => ({
                slag_id: item.id,
                slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
              })
            );
          }
        }
        this.content.urlConditionNoDisplay = sale_advance.url_not_met_hiding_adv
          ? sale_advance.url_not_met_hiding_adv
          : "";
        this.content.urlNoConditionDisplay =
          sale_advance.url_not_met_condition_adv
            ? sale_advance.url_not_met_condition_adv
            : "";
        this.content.imagePre = this.contentById.image_url
          ? Constants.URL_BE + "/storage/" + this.contentById.image_url
          : "";
        if (sale_other.newsletters && sale_other.newsletters.length > 0) {
          this.content.mailMega = sale_other.newsletters.map((item) => ({
            value: item.id,
            text: item.title,
          }));
        }
      }
    },
    listContents() {
      let listContents = [];
      listContents = this.listContents.map((item) => ({
        value: item.id,
        text: item.title,
      }));
      listContents.forEach((element) => {
        this.listContentOptions.push(element);
      });
      if (this.$route.params.id) {
        this.listContentOptions = this.listContentOptions.filter(
          (item) => item.value != this.$route.params.id
        );
      }
      this.loadingContent = false;
    },
    saleSettingById() {
      if (
        this.saleSettingById &&
        Object.keys(this.saleSettingById).length > 0
      ) {
        if (this.contentById) {
          this.content.price = this.content.price.map((e) => {
              const new_element = { ...e };
              if (this.saleSettingById.payment_method_default !== null) {
                new_element.defaultPayment = this.saleSettingById.payment_method_default
              }
              return new_element;
            });
        }
        this.optionsDefault_payment = this.optionsDefault_payment.filter(
          (item) => {
            return (
              this.saleSettingById.payment_default.filter((itemPayment) =>
                item.value.includes(itemPayment)
              ).length == item.value.length
            );
          }
        );
        this.optionsSubscriptionDefault_payment =
          this.optionsSubscriptionDefault_payment.filter((item) => {
            return (
              this.saleSettingById.subscription_default.filter((itemPayment) =>
                item.value.includes(itemPayment)
              ).length == item.value.length
            );
          });
        this.urlPageThankCreditDefault =
          this.saleSettingById.url_page_thanks_card;
        this.urlPageThankTransferDefault =
          this.saleSettingById.url_page_thanks_transfer;
      }
    },
    listCategoriesInShop() {
      this.listCategoryOptions = this.listCategoriesInShop.map((item) => ({
        text:
          item.category_name.length > Constants.MAX_LENGTH
            ? item.category_name.substr(0, Constants.MAX_LENGTH) + "..."
            : item.category_name,
        value: item.id.toString(),
      }));
      if (this.isCreateCat) {
        this.category_id =
          this.listCategoriesInShop && this.listCategoriesInShop[0]
            ? this.listCategoriesInShop[0].id
            : "";
        this.isCreateCat = false;
      }
      this.isCreateCategory = false;
    },
    //Slag
    slag_id() {},
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
      }));
      // this.addSlag();
    },
    //Author
    listAuthor() {
      if (this.listAuthor.length > 0) {
        this.listAuthorOptions = this.listAuthor.map((item) => ({
          text:
            item && item.name && item.name.length > Constants.MAX_LENGTH
              ? item.name.substr(0, Constants.MAX_LENGTH) + "..."
              : item.name,
          value: item.id.toString(),
        }));
      } else {
        this.listAuthorOptions = [
          { text: "リストは空です", value: null, disabled: true },
        ];
      }
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        if (this.isCreateCategory) {
          const request = {
            shop_id: this.shop_id,
          };
          this.getListCategoriesInShop(request);
        }
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listSurvey() {
      this.listSurveys = this.listSurvey.data.map((item) => ({
        text: item.survey_title,
        value: item.id,
      }));
      this.listSurveys.unshift({
        value: "",
        text: "アンケートを選んでください",
      });
    },
    listMailMega() {
      this.listMailMegas = this.listMailMega.map((item) => ({
        value: item.id,
        text: item.title,
      }));
    },
    ListSettingShop() {
      if (
        !this.$route.params.id &&
        this.admin_info.user_type != this.type_author
      ) {
        this.dataShop = this.ListSettingShop;
        if (this.dataShop.length > 0) {
          if (this.dataShop[0].logo != null) {
            this.logoShop = Constants.URL_BE + "/" + this.dataShop[0].logo;
          }
          this.content.author_id = this.dataShop[0].author_default
            ? this.dataShop[0].author_default
            : "";
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "contentById",
      "listCategoriesInShop",
      "success",
      "message",
      "error",
      "listSlag",
      "listAuthor",
      "listSurvey",
      "listMailMega",
      "saleSettingById",
      "listContents",
      "ListSettingShop",
    ]),
  },
  methods: {
    ...mapActions({
      getContentById: "getContentById",
      getListCategoriesInShop: "getListCategoriesInShop",
      getALLlistSlag: "getALLlistSlag",
      getListSurvey: "getListSurvey",
      getListAuthorInShop: "getListAuthorInShop",
      CreateAuthor: "CreateAuthor",
      getlistMailMega: "getlistMailMega",
      getSaleSettingAdmin: "getSaleSettingAdmin",
      getListContents: "getListContents",
      CreateCategoryAdmin: "CreateCategoryAdmin",
      getListSettingShopAdmin: "getListSettingShopAdmin",
    }),
    getChildrent(div) {
      if (div.innerHTML[0] == "<" && div.innerHTML.substr(0, 4) != "<br>") {
        let children = div.children[0];
        if (children) {
          return this.getChildrent(children);
        } else {
          return div;
        }
      } else {
        return div;
      }
    },
    async createContent() {
      let shop_id = this.shop_id;
      let id = this.$route.params.id ? this.$route.params.id : "";
      const {
        urlClickItemContent,
        urlNoConditionDisplay,
        urlConditionNoDisplay,
        author_id,
        product_title,
        product_type,
      } = this.content;
      let error = false;
      // Memo: そもそもコンテンツ公開設定と、オファー設定でバリデーションが共有されている？
      // そのため判断しづらい処理があると想定できる(shimazaki)
      // Memo: バリデーションは別で分けるか専用のライブラリを使った方が良さそう(hamada)
      // product_typeのバリデーションを追加
      if (!product_type) {
        this.$toasted.error("オファー内容設定を選択してください。");
        this.isLoadingCreateContent = false;
        this.check = null;
        error = true;
      }
      if (!product_title && product_type != 3) {
        this.$toasted.error("商品タイトルを入力してください。");
        this.isLoadingCreateContent = false;
        this.check = null;
        error = true;
      }
      if (!author_id) {
        this.$toasted.error("著者を選択して下さい。");
        this.isLoadingCreateContent = false;
        this.check = null;
        error = true;
      }
      for (let i = 0; i < this.content.price.length; i++) {
        let element = this.content.price[i];
        if (product_type != 3) {
          if (!element.product_price) {
            this.$toasted.error("基本価格を入力してください。");
            this.isLoadingCreateContent = false;
            this.check = null;
            error = true;
          }
          if (
            element.product_price &&
            !(element.product_price + "").match(Constants.REGEX_NUMBER)
          ) {
            this.$toasted.error("基本価格は半角数字で入力してください");
            this.isLoadingCreateContent = false;
            this.check = null;
            error = true;
          }
          if (element.sale_format == "1" && !element.addBill) {
            this.$toasted.error("請求間隔を追加するを入力してください。");
            this.isLoadingCreateContent = false;
            this.check = null;
            error = true;
          }
          if (element.sale_format == "1") {
            if (!(element.defaultSubscription + "")) {
              this.$toasted.error("決済方法を選択して下さい。");
              this.isLoadingCreateContent = false;
              this.check = null;
              error = true;
            }
          } else if (element.sale_format == "0") {
            if (!(element.defaultPayment + "")) {
              this.$toasted.error("決済方法を選択して下さい。");
              this.isLoadingCreateContent = false;
              this.check = null;
              error = true;
            }
          }
          if (
            element.tax &&
            !(element.tax + "").match(Constants.REGEX_NUMBER)
          ) {
            this.$toasted.error("税率は半角数字で入力してください");
            this.isLoadingCreateContent = false;
            this.check = null;
            error = true;
          }
          if (
            element.fee &&
            !(element.fee + "").match(Constants.REGEX_NUMBER)
          ) {
            this.$toasted.error("送料は半角数字で入力してください");
            this.isLoadingCreateContent = false;
            this.check = null;
            error = true;
          }
          if (
            element.priceOverTime &&
            !(element.priceOverTime + "").match(Constants.REGEX_NUMBER)
          ) {
            this.$toasted.error("期間限定価格は半角数字で入力してください");
            this.isLoadingCreateContent = false;
            this.check = null;
            error = true;
          }
          if (
            this.content.product_type == "2" &&
            ((element.sale_format == "0" &&
              element.defaultPayment.includes(2)) ||
              (element.defaultSubscription.includes(2) &&
                element.sale_format == "1"))
          ) {
            this.$toasted.error(
              "オファー内容設定が簡単決済の場合、簡単決済を銀行振込と設定できません。"
            );
            this.isLoadingCreateContent = false;
            this.check = null;
            error = true;
          }
          if (
            this.content.product_type == "2" &&
            ((element.sale_format == "0" &&
              element.defaultPayment.length == 2) ||
              (element.sale_format == "1" &&
                element.defaultSubscription.length == 2))
          ) {
            this.$toasted.error("決済方法を選択して下さい。");
            this.isLoadingCreateContent = false;
            this.check = null;
            error = true;
          }
          // 外部決済
          if (element.sale_format === "0" && element.defaultPayment.indexOf(3) !== -1) {
            // 決済URL（必須・URL形式）
            if (!element.externalPaymentUrl || !element.externalPaymentUrl.match(Constants.REGEX_URL)) {
              this.$toasted.error("ステップ４：利用予定の外部決済URLをご入力ください。");
              this.isLoadingCreateContent = false;
              this.check = null;
              error = true;
            }
            // リファラURL（不正利用選択時必須）
            if (element.externalPaymentAutoApproval === 2 && !element.externalPaymentReferrerCheckText) {
              this.$toasted.error("ステップ５：リファラーURLをご入力ください。");
              this.isLoadingCreateContent = false;
              this.check = null;
              error = true;
            }
            // 外部決済URL
            if (element.externalPaymentThankUrl && !element.externalPaymentThankUrl.match(Constants.REGEX_URL)) {
              this.$toasted.error("決済後の移遷先URLはURLの形式で入力ください。");
              this.isLoadingCreateContent = false;
              this.check = null;
              error = true;
            }
          }
        } else {
          if (element.minInputPrice < 100) {
            this.$toasted.error("¥100以上のご入力に対応しております。");
            this.isLoadingCreateContent = false;
            this.check = null;
            error = true;
          }
        }
        if (error) return;
        // check method payment
        if (element.sale_format == 0) {
          if (element.defaultPayment.length == 0) {
            error = true;
          }
        } else {
          if (element.defaultSubscription.length == 0) {
            error = true;
          }
        }
        if (
          element.product_type == 3 &&
          element.defaultMinInputPrice.length == 0
        ) {
          error = true;
        }
        if (element.fromTimeRelease > element.toTimeRelease) {
          error = true;
        }
        if (element.typeUrlPageThank == 0) {
          if (
            (element.urlPageThankCredit &&
              !element.urlPageThankCredit.match(Constants.REGEX_URL)) ||
            (element.urlPageThankTransfer &&
              !element.urlPageThankTransfer.match(Constants.REGEX_URL))
          ) {
            error = true;
          }
        }
        if (
          element.typeUrlPageThank == 1 &&
          element.listSlagSetting.length > 0
        ) {
          element.listSlagSetting.forEach((slag) => {
            if (
              (slag.urlCreditCard &&
                !slag.urlCreditCard.match(Constants.REGEX_URL)) ||
              (slag.urlTransfer && !slag.urlTransfer.match(Constants.REGEX_URL))
            ) {
              error = true;
            }
          });
        }
      }
      if (
        (urlClickItemContent &&
          !urlClickItemContent.match(Constants.REGEX_URL)) ||
        (urlNoConditionDisplay &&
          !urlNoConditionDisplay.match(Constants.REGEX_URL)) ||
        (urlConditionNoDisplay &&
          !urlConditionNoDisplay.match(Constants.REGEX_URL))
      ) {
        error = true;
      }
      if (error) {
        this.$toasted.error("必須項目のご入力をお願いします。");
        this.isLoadingCreateContent = false;
        this.check = null;
        return;
      }
      // Memo: アップロード処理（別で切り出した方がよさそう）(hamada)
      //upload img
      var urlImg = "";
      // eslint-disable-next-line no-unused-vars
      var checkImg = true;
      if (this.content.img) {
        urlImg = await this.handleImageAdded(this.content.img);
        if (!urlImg) {
          checkImg = false;
        }
      } else {
        if (id) {
          urlImg = this.content.imagePre;
        }
      }
      //cut domain + storage for new url (request change)
      if (urlImg) {
        urlImg = urlImg.replace(
          location.protocol +
            "//" +
            location.hostname +
            (location.port ? ":" + location.port : "") +
            "/be/storage/",
          ""
        );
        urlImg = urlImg.replace(Constants.URL_BE + "/storage/", "");
      }
      if (this.content.price && this.content.price.length > 0) {
        this.content.price.forEach((element) => {
          if (element.typeUrlPageThank == 0) {
            if (element.listSlagSetting && element.listSlagSetting.length > 0) {
              element.listSlagSetting = null;
            }
          } else {
            element.urlPageThankCredit = null;
            element.urlPageThankTransfer = null;
          }
        });
      }
      if (checkImg) {
        const request = {
          shop_id: shop_id,
          product_type: this.content.product_type,
          title: this.content.product_title,
          column_price:
            this.content.price && this.content.price.length > 0
              ? this.content.price
              : [],
          message_below_button: this.content.textBelowButton
            ? this.content.textBelowButton
            : " ",
          message_in_button: this.content.textButton
            ? this.content.textButton
            : " ",
          column_other:
            this.content.listDisplay && this.content.listDisplay.length > 0
              ? this.content.listDisplay
              : [],
          url_not_met_condition_oth: this.content.urlNoConditionDisplay
            ? this.content.urlNoConditionDisplay
            : " ",
          url_not_met_hidden_oth: this.content.urlConditionNoDisplay
            ? this.content.urlConditionNoDisplay
            : " ",
          url_item_click_redirect: this.content.urlClickItemContent
            ? this.content.urlClickItemContent
            : " ",
          user_input: this.content.user_input,
          url_video: "",
          purchase_credit_card: "",
          bank_transfer: "",
          branch_by_slag: "",
          newsletter_id: [],
          shop_public: this.content.isPublic,
          allow_pre_sale: this.content.allowPreSale,
          sale_format: this.content.typeConditionSlag,
          countdown_type: 0,
          content_id_1_adv: [],
          content_id_2_adv: [],
          content_id_3_adv: [],
          content_id_4_adv: [],
          slag_id_1_adv: [],
          slag_id_2_adv: [],
          slag_id_3_adv: [],
          slag_id_4_adv: [],
          url_not_met_condition_adv: this.content.urlNoConditionDisplay
            ? this.content.urlNoConditionDisplay
            : " ",
          url_not_met_hiding_adv: this.content.urlConditionNoDisplay
            ? this.content.urlConditionNoDisplay
            : " ",
          checkbox_url_redirect: this.content.isUrlClickContent,
          image_url: urlImg,
          author_id: this.content.author_id,
          tags: this.content.tags ? this.content.tags : " ",
          categories: [],
          setting_purchase: 0,
          design_settings: 0,
        };

        //newletter
        if (this.content.mailMega && this.content.mailMega.length > 0) {
          this.content.mailMega.forEach((value) => {
            request.newsletter_id.push(value.value);
          });
        }

        if (
          this.content.typeConditionSlag == this.constant_display["SET_CONTENT"]
        ) {
          if (
            this.content.content_basic &&
            this.content.content_basic.length > 0
          ) {
            this.content.content_basic.forEach((value) => {
              request.content_id_1_adv.push(value.value);
            });
          }
          if (
            this.content.content_basic.length > 0 &&
            this.content.content_basic1 &&
            this.content.content_basic1.length > 0
          ) {
            this.content.content_basic1.forEach((value) => {
              request.content_id_2_adv.push(value.value);
            });
          }
          if (
            this.content.content_basic.length > 0 &&
            this.content.content_basic1.length > 0 &&
            this.content.content_basic2 &&
            this.content.content_basic2.length > 0
          ) {
            this.content.content_basic2.forEach((value) => {
              request.content_id_3_adv.push(value.value);
            });
          }
          if (
            this.content.content_hidden &&
            this.content.content_hidden.length > 0
          ) {
            this.content.content_hidden.forEach((value) => {
              request.content_id_4_adv.push(value.value);
            });
          }
        } else if (
          this.content.typeConditionSlag == this.constant_display["SET_SLAG"]
        ) {
          if (this.content.slags_basic && this.content.slags_basic.length > 0) {
            this.content.slags_basic.forEach((value) => {
              request.slag_id_1_adv.push(value.slag_id);
            });
          }
          if (
            this.content.slags_basic.length > 0 &&
            this.content.slags_basic1 &&
            this.content.slags_basic1.length > 0
          ) {
            this.content.slags_basic1.forEach((value) => {
              request.slag_id_2_adv.push(value.slag_id);
            });
          }
          if (
            this.content.slags_basic.length > 0 &&
            this.content.slags_basic1.length > 0 &&
            this.content.slags_basic2 &&
            this.content.slags_basic2.length > 0
          ) {
            this.content.slags_basic2.forEach((value) => {
              request.slag_id_3_adv.push(value.slag_id);
            });
          }
          if (this.content.slag_hidden && this.content.slag_hidden.length > 0) {
            this.content.slag_hidden.forEach((value) => {
              request.slag_id_4_adv.push(value.slag_id);
            });
          }
        }

        if (
          this.content.list_category &&
          this.content.list_category.length > 0
        ) {
          this.content.list_category.forEach((value) => {
            request.categories.push(value.value);
          });
        }
        if (id) {
          request.id = id;
          const dataReturn = await this.$store.dispatch(
            "updateSaleContent",
            request
          );
          this.check = null;
          if (dataReturn.success) {
            this.isLoadingCreateContent = false;
            // 外部決済戻りURLの再取得
            if (!this.content.price[0].externalPaymentRedirectUrlIdentifier) {
              if (this.$route.params.id) {
                const reqData = {
                  id: this.$route.params.id,
                  shop_id: this.shop_id,
                };
                await this.getContentById(reqData);
              }
            }
          } else {
            this.isLoadingCreateContent = false;
          }
        } else {
          const dataReturn = await this.$store.dispatch(
            "createSaleContent",
            request
          );
          this.check = null;
          if (dataReturn.success) {
            this.isLoadingCreateContent = false;
            this.$router.push({
              name: this.$route.params.shopId
                ? "list content"
                : "list content domain",
            });
          } else {
            this.isLoadingCreateContent = false;
          }
        }
      }
      this.check = null;
    },
    saveContentType() {},
    async addTag(newTag) {
      this.isCreateCategory = true;
      const formCreateCategory = {
        shop_id: this.shop_id,
        category_name: newTag,
        category_id:
          newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.CreateCategoryAdmin(formCreateCategory);
    },
    returnList() {
      localStorage.removeItem(Constants.CONTENT);
      this.$router.go(-1);
    },
    ClickContentSelected(value, index) {
      this.content.price[this.isSelectedPrice].billInternal = value;
      this.content.price[this.isSelectedPrice].textChange =
        value == 1 ? "日" : value == 2 ? "週間" : value == 3 ? "ヶ月" : "年";
      this.isSelected = index + 1;
    },
    ClickPriceSelected(value, index) {
      this.isSelectedPrice = index;
    },
    addPrice(length) {
      this.listPrice.push({
        value: length + 1,
        text: "価格" + (length + 1),
      });
      this.content.price.push({
        type: "",
        product_title: "",
        sale_format: 0,
        product_price: "",
        priceOverTime: "",
        initialCost: "",
        trialPeriod: "",
        billInternal: 0,
        addBill: "",
        textChange: "",
        billingCount: "",
        tax: "",
        fee: "",
        baseSlag: [],
        textPrice: "",
        textButton: "",
        textBelowButton: "",
        defaultPayment: [],
        defaultSubscription: [],
        listSlagSetting: [{ id: "", urlCreditCard: "", urlTransfer: "" }],
        typeUrlPageThank: 0,
        urlPageThankCredit: "",
        urlPageThankTransfer: "",
        isBuyDuplicate: 0,
        urlDuplicate: "",
        minInputPrice: "",
        defaultMinInputPrice: [],
      });
      if (this.saleSettingById) {
        this.configureContentPrice();
      }
    },
    removeTypeDisplay(index, value = null) {
      if (value) {
        this.content.listDisplay.forEach((element, index) => {
          if (element.value == value) {
            this.content.listDisplay.splice(index, 1);
            this.allHistoryEditor.splice(index, 1);
            this.allIdUndo.splice(index, 1);
          }
        });
      } else {
        this.content.listDisplay.splice(index, 1);
      }
    },
    addSettingRedirect(item, index) {
      item.splice(index + 1, 0, {
        conditions: [
          {
            typeConditon: 1,
            id: [],
            ownershipCondition: 1,
          },
        ],
        urlCreditCard: null,
        urlTransfer: null,
      });
    },
    goToCreateContent() {
      this.isLoadingConfirm = true;
      if (this.$route.params.shopId) {
        if (this.isEdit) {
          this.$router.push({
            name: "Edit Content Change",
            params: {
              id: this.$route.params.id,
              shopId: this.$route.params.shopId,
            },
          });
        } else {
          this.$router.push({
            name: "Content Change",
            params: {
              shopId: this.$route.params.shopId,
            },
          });
        }
        this.isLoadingConfirm = false;
      } else {
        if (this.isEdit) {
          this.$router.push({
            name: "Edit Content Change domain",
            params: {
              id: this.$route.params.id,
            },
          });
        } else {
          this.$router.push({
            name: "Content Change domain",
          });
        }
        this.isLoadingConfirm = false;
      }
    },
    configureContentPrice() {
      this.content.price = this.content.price.map((e) => {
        const new_element = { ...e };
        if (new_element.fee === null || new_element.fee === "") {
          new_element.fee = this.saleSettingById.consultation_fee_default;
        }

        if (new_element.tax === null || new_element.tax === "") {
          new_element.tax = this.saleSettingById.tax_default;
        }
        return new_element;
      });
    },

    fomatObject(array, object) {
      if (array && array.length > 0) {
        let ArrrayObject = [];
        Object.keys(object)
          .filter((key) => array.includes(parseInt(key)))
          .reduce((obj, key) => {
            ArrrayObject.push(object[key]);
          }, {});
        return ArrrayObject;
      }
    },

    uploadImg(e) {
      if (!/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/.test(e.target.value)) {
        this.$toasted.error(
          "「jpeg」「jpg」「png」形式の画像をご利用ください。"
        );
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        return false;
      } else if (
        e.target.files[0] &&
        e.target.files[0].size / 1024 / 1024 > 100
      ) {
        this.$toasted.error("画像1枚につき100MBまで");
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        return false;
      } else {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          let data;
          if (typeof e.target.result === "object") {
            data = window.URL.createObjectURL(new Blob([e.target.result]));
          } else {
            data = e.target.result;
            this.$refs.cropper.replace(e.target.result);
          }
          this.option.img = data;
        };
        reader.readAsArrayBuffer(file);
        this.content.fileName = file.name;
        this.content.imagePre = null;
      }
    },
    realTime(data) {
      this.previews = data.image;
      const dataImage = data.canvas.toDataURL("image/jpeg");
      if (this.option.img) {
        var arr = dataImage.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        this.content.img = new File([u8arr], this.content.fileName, {
          type: mime,
        });
        this.content.imgCheck = dataImage;
      }
    },
    addEditor() {
      this.isEditor = true;
      this.content.listDisplay.push({
        value: uuidv4(),
        text: "",
        title: "",
        content: "",
        show: true,
        focus: false,
      });
      const index = this.content.listDisplay.length - 1;
      this.allHistoryEditor[index] = [null];
      this.allIdUndo[index] = 0;
    },
    selectOption(text, value) {
      this.content.listDisplay.forEach((element, index) => {
        if (element.value == value) {
          this.content.listDisplay[index].text = text;
          if (text == "自由記入") {
            this.content.listDisplay[index].focus = true;
            // this.checkFocusEditor = true;
          }
        }
      });
    },
    focusEditor(value) {
      this.content.listDisplay.forEach((element, index) => {
        if (element.value == value) {
          var focusNow = this.content.listDisplay[index].focus;
          if (focusNow == true) {
            this.content.listDisplay[index].focus = false;
          } else {
            this.content.listDisplay[index].focus = true;
          }
        }
      });
      // if (this.content.listDisplay.some((data) => data.focus == true)) {
      //   this.checkFocusEditor = true;
      // } else {
      //   this.checkFocusEditor = false;
      // }
    },
    async changePDF(e) {
      var formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("id", this.shop_id);
      if (e.target.files[0].size / 1024 / 1024 > 10) {
        this.$toasted.error("PDFファイルは10MBを超えこえてはなりません");
      } else {
        this.loadingUpload = true;
        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            this.chapter_content +=
              '<iframe class="ql-pdf" frameborder="0" allowfullscreen="true" src="' +
              url +
              '"></iframe><p><br></p>';
            this.loadingUpload = false;
          })
          .catch(() => {
            this.loadingUpload = false;
          });
      }
    },

    configContentThankPage() {
      this.content.price[0].urlPageThankCredit = this.urlPageThankCreditDefault
        ? this.urlPageThankCreditDefault
        : "";
      this.content.price[0].urlPageThankTransfer = this
        .urlPageThankTransferDefault
        ? this.urlPageThankTransferDefault
        : "";
      this.content.price[0].listSlagSetting[0].urlCreditCard = this
        .urlPageThankCreditDefault
        ? this.urlPageThankCreditDefault
        : "";
      this.content.price[0].listSlagSetting[0].urlTransfer = this
        .urlPageThankTransferDefault
        ? this.urlPageThankTransferDefault
        : "";
    },
    async setValueLP(e, ind) {
      this.content.listDisplay[ind].content = e;
      this.check += 1;
      if (this.check == this.listDisplayCheck.length) {
        this.check = 0;
        if (this.contentPos != null) {
          let content = this.content.listDisplay[this.contentPos].content;
          if (
            this.typeEvent == "copy" &&
            content.blocks &&
            content.blocks.length > 0
          ) {
            this.typeEvent = null;
            this.contentPos = null;
            const selft = this;
            navigator.clipboard.writeText(JSON.stringify(e)).then(
              function () {
                selft.$toasted.success("内容がコピーされました。");
              },
              function () {
                selft.$toasted.error("エラーでコピーできませんでした。");
              }
            );
            return;
          }
          if (this.typeEvent == "paste") {
            this.typeEvent = null;
            let data = await navigator.clipboard.readText();
            if (
              data &&
              data.includes("blocks") &&
              data.includes("time") &&
              data.includes("version")
            ) {
              let dataObj = JSON.parse(data);
              if (dataObj.blocks.length == 0) return;
              if (content && content.blocks.length > 0) {
                if (
                  content.blocks.length == 1 &&
                  content.blocks[0].data.text == ""
                ) {
                  content.blocks.splice(0, 1);
                }
                dataObj.blocks.forEach((ele) => {
                  content.blocks.push(ele);
                });
              } else {
                content = dataObj;
              }
              this.content.listDisplay[this.contentPos].content = content;
              content["id"] = "editor-" + this.contentPos;
              this.editorChange(content);
              this.content.listDisplay[this.contentPos].text = "制作者情報";
              const seflt = this;
              setTimeout(function () {
                seflt.content.listDisplay[seflt.contentPos].text = "自由記入";
                seflt.contentPos = null;
              }, 100);
            } else if (data) {
              this.dataEditor = e;
              data = data.replace(/\n/g, "<br>");
              if (
                content.blocks.length == 1 &&
                content.blocks[0].data.text == ""
              ) {
                content.blocks.splice(0, 1);
              }
              content.blocks.push({
                id: (Math.random() + 1).toString(36).substring(0, 10),
                type: "paragraph",
                data: {
                  text: data,
                  alignment: "left",
                  lineHeight: null,
                },
                tunes: {
                  anyTuneName: {
                    alignment: "left",
                  },
                },
              });
              this.content.listDisplay[this.contentPos].content = content;
              content["id"] = "editor-" + this.contentPos;
              this.editorChange(content);
              this.content.listDisplay[this.contentPos].text = "制作者情報";
              const seflt = this;
              setTimeout(function () {
                seflt.content.listDisplay[seflt.contentPos].text = "自由記入";
                seflt.contentPos = null;
              }, 100);
            }
            return;
          }
        }
        this.createContent();
      }
    },
    saveContent() {
      this.isLoadingCreateContent = true;
      this.listDisplayCheck = this.content.listDisplay.filter(
        (item) => item.text == "自由記入"
      );
      if (this.listDisplayCheck && this.listDisplayCheck.length > 0) {
        eventBus.$emit("getFormEditor");
      } else {
        this.createContent();
      }
    },
    copyPage(index) {
      this.contentPos = index;
      this.check = 0;
      this.typeEvent = "copy";
      this.listDisplayCheck = this.content.listDisplay.filter(
        (item) => item.text == "自由記入"
      );
      eventBus.$emit("getFormEditor");
    },
    pastePage(index) {
      this.contentPos = index;
      this.check = 0;
      this.typeEvent = "paste";
      this.listDisplayCheck = this.content.listDisplay.filter(
        (item) => item.text == "自由記入"
      );
      eventBus.$emit("getFormEditor");
    },

    async handleImageAdded(file) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        this.$toasted.error("画像がjpg、jpeg、png　形式である必要があります。");
        this.isLoading = false;
      } else {
        if (file.size / 1024 / 1024 > 100) {
          this.$toasted.error("画像1枚につき100MBまで");
          this.isLoading = false;
        } else {
          var formData = new FormData();
          formData.append("image", file);
          formData.append("id", this.shop_id);

          const url = `${Urls.CONTENT}/${Urls.UPLOAD}`;
          const method = "POST";
          return await Api.adminRequestServer({
            method,
            url,
            data: formData,
          })
            .then((response) => {
              return response.data.data; // Get url from response
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    setCondition(item, index) {
      if (!item) {
        this.conditions = [
          {
            typeConditon: 1,
            id: [],
            ownershipCondition: 1,
          },
        ];
      } else {
        this.conditions = item.map((val) => {
          return JSON.parse(JSON.stringify(val));
        });
      }
      this.showPopupSetCondition = true;
      this.indexConditionEdit = index;
    },
    saveCondition() {
      this.content.price[0].listSlagSetting[
        this.indexConditionEdit
      ].conditions = this.conditions.filter((val) => {
        if (val.id.length > 0) return JSON.parse(JSON.stringify(val));
      });
      this.showPopupSetCondition = false;
    },
    subSetting(item, index) {
      item.splice(index, 1);
    },
    changeTypeCondition(condition) {
      condition.id = [];
    },
    subCondition(index) {
      this.conditions.splice(index, 1);
    },
    addCondition(index) {
      this.conditions.splice(index + 1, 0, {
        typeConditon: 1,
        id: [],
        ownershipCondition: 1,
      });
    },
    upSort(index) {
      this.positionPaste = window.scrollY;
      this.contentPos = index;
      this.isDisabled = true;
      let newIndex =
        this.contentPos > 0
          ? this.contentPos - 1
          : this.content.listDisplay.length - 1;

      this.content.listDisplay = this.array_move(
        this.content.listDisplay,
        this.contentPos,
        newIndex
      );
      this.allHistoryEditor = this.array_move(
        this.allHistoryEditor,
        this.contentPos,
        newIndex
      );
      this.allIdUndo = this.array_move(
        this.allIdUndo,
        this.contentPos,
        newIndex
      );
      this.isDisabled = false;
    },
    downSort(index) {
      this.positionPaste = window.scrollY;
      this.contentPos = index;
      this.isDisabled = true;
      let newIndex =
        this.contentPos == this.content.listDisplay.length - 1
          ? 0
          : this.contentPos + 1;

      this.content.listDisplay = this.array_move(
        this.content.listDisplay,
        this.contentPos,
        newIndex
      );
      this.allHistoryEditor = this.array_move(
        this.allHistoryEditor,
        this.contentPos,
        newIndex
      );
      this.allIdUndo = this.array_move(
        this.allIdUndo,
        this.contentPos,
        newIndex
      );
      this.isDisabled = false;
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    },
    editorChange(data) {
      const index = data["id"].split("-")[1];
      if (!this.allHistoryEditor[index]) {
        this.allHistoryEditor[index] = [data];
        this.allIdUndo[index] = 0;
        return;
      }
      let historyEditor = this.allHistoryEditor[index];
      let idUndo = this.allIdUndo[index];
      const dataHis = historyEditor[historyEditor.length - idUndo - 1];
      if (
        dataHis &&
        JSON.stringify(dataHis.blocks) == JSON.stringify(data.blocks)
      )
        return;
      if (idUndo > 0) {
        historyEditor = historyEditor.slice(0, historyEditor.length - idUndo);
        this.allIdUndo[index] = 0;
      }
      historyEditor.push(data);
      if (historyEditor.length <= 20) {
        historyEditor = historyEditor.slice(
          historyEditor.length - 19,
          historyEditor.length
        );
      }
      this.allHistoryEditor[index] = historyEditor;
    },
    undo(index) {
      let historyEditor = this.allHistoryEditor[index];
      let idUndo = this.allIdUndo[index] ? this.allIdUndo[index] : 0;
      if (
        historyEditor.length < 1 ||
        historyEditor == undefined ||
        historyEditor.length <= idUndo + 1
      )
        return;
      idUndo += 1;
      const dataEditor =
        historyEditor.length > 1
          ? historyEditor[historyEditor.length - idUndo - 1]
          : null;
      this.content.listDisplay[index].content = dataEditor;
      this.allIdUndo[index] = idUndo;
      this.content.listDisplay[index].text = "制作者情報";
      const seflt = this;
      setTimeout(function () {
        seflt.content.listDisplay[index].text = "自由記入";
        seflt.contentPos = null;
      }, 100);
    },
    redo(index) {
      let historyEditor = this.allHistoryEditor[index];
      let idUndo = this.allIdUndo[index];
      if (idUndo == undefined || idUndo <= 0 || historyEditor.length <= 0)
        return;
      idUndo -= 1;
      const dataEditor = historyEditor[historyEditor.length - idUndo - 1];
      this.allIdUndo[index] = idUndo;
      this.content.listDisplay[index].content = dataEditor;
      this.content.listDisplay[index].text = "制作者情報";
      const seflt = this;
      setTimeout(function () {
        seflt.content.listDisplay[index].text = "自由記入";
        seflt.contentPos = null;
      }, 100);
    },
    copyURL(value) {
      navigator.clipboard.writeText(value);
      this.$toasted.success(Constants.MESSAGE_COPY);
    },
    checkCopyUrl(value) {
      return value.match(Constants.REGEX_URL);
    }
  },
};
</script>
<style lang="scss">
.btn-collapse:after {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid black;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  transform: rotate(180deg);
  transition: all linear 0.25s;
  float: right;
  position: absolute;
  top: 30%;
  right: 10px;
}
button.btn-collapse {
  width: 500px;
  text-align: left;
  position: relative;
}
.btn-collapse.close-collapse:after {
  transform: rotate(0deg);
}
.title-content {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  @media (max-width: 767px) {
    display: block;
    margin-left: 0;
  }
  div {
    button {
      float: right;
      margin-left: 10px;
      @media (max-width: 767px) {
        float: none;
        margin-left: 0;
        display: block;
        margin-top: 10px;
      }
    }
  }
}
.title-btn {
  display: flex;
  margin-top: 20px;
  align-items: center;
  @media (max-width: 767px) {
    display: block;
    button {
      @media (max-width: 767px) {
        display: block;
        margin-top: 10px;
      }
    }
  }
}
.radio-type {
  display: flex;
  @media (max-width: 767px) {
    display: block;
  }
}
.tax-fee {
  display: flex;
  @media (max-width: 767px) {
    display: block;
  }
}
.btn-tab-sale-active {
  background: #ff644e;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 160px;
}
.btn-tab-sale-inactive {
  color: #636f83;
  border-color: #636f83;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #636f83;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 160px;
}
.btn-tab-sale-active:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ff644e;
}
.btn-toggle-active {
  display: inline-block;
  position: relative;
  .btn-checkbox-active {
    border: 0;
  }
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    inset: 0;
    &:checked ~ .btn-checkbox-active {
      background: #ff644e;
      color: #fff;
    }
  }
}
.btn-toggle-active {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}
.edit-content-main {
  label {
    // margin-bottom: 0;
  }
  .custom-button {
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: #595959;
    border-radius: 50%;
    border-color: #595959;
    display: block;
    margin-left: 1%;
    position: relative;
    padding: 0;
    .icon {
      width: 40px;
      height: 40px;
    }
  }
}
.page-list-content {
  padding: 0px;
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0px !important;
  }
}
.custom-label {
  border: 1px solid #b5b5b5;
  padding: 20px;
  width: 100%;
  text-align: left;
  margin: 0px;
}
.customBackGround {
  border-radius: 25px;
  background-color: #d9d9d9;
}
>>> .modalCustom > .modal-dialog {
  max-width: 720px !important;
  margin: 1.75rem auto;
}
.customSelectAdd {
  border: 1px solid #d9d9d9;
  background-color: #d9d9d9;
}
.border-titlePage {
  text-align: center;
  padding: 5px 30px 5px 30px;
  border: 2px solid #828282;
  margin-top: revert;
  margin-bottom: revert;
  background-color: #f2f2f2;
}
.customListPage {
  .custom-button {
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: #595959;
    border-radius: 50%;
    border-color: #595959;
    display: block;
    margin-left: 1%;
    position: relative;
    .custom-span {
      position: absolute;
      top: -61%;
      right: 25.6%;
      font-size: 50px;
    }
  }
}
.textPage {
  width: 95%;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.custom-button-add-chapter {
  margin: 0px 20px 1.5rem;
  background-color: #0083cf;
  border-color: #0083cf;
  color: #fff;
}
// .custom-button-add-chapter:hover {
//   margin: 0px 20px 1.5rem;
//   background-color: #0083cf;
//   border-color: #0083cf;
//   color: #fff;
// }
.custom-button-add-chapter:not(:disabled):not(.disabled):active,
.show > .custom-button-add-chapter.dropdown-toggle {
  // margin: 0px 20px 1.5rem;
  background-color: #0083cf;
  border-color: #0083cf;
  color: #fff;
}
.cropper-component {
  width: 100%;
  height: 300px;
  margin: 30px auto;
}
.show-preview {
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: whitesmoke;
  background-size: cover;
  width: 300px;
  height: 300px;
}
@media (max-width: 1024px) {
  .btn-tab-sale-inactive {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
  .btn-tab-sale-active {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
}
@media (max-width: 450px) {
  .btn-tab-sale-inactive {
    margin-top: 10px;
  }
  .btn-tab-sale-active {
    margin-top: 10px;
  }
  .btn-return-respon {
    margin-top: 5px;
  }
}
.sale-btn {
  width: 120px !important;
  height: 42px !important;
  background-color: #00b797 !important;
  color: #fff !important;
  border-radius: 6px !important;
  font-size: 20px;
}
.sale-btn:hover {
  width: 120px !important;
  height: 42px !important;
  background-color: #00ab82 !important;
  color: #fff !important;
  border-radius: 6px !important;
}
.select-display {
  width: 500px;
  overflow-y: auto;
}
.editor-collapse {
  .row-input input {
    margin: unset !important;
  }
  .cdx-search-field__input {
    height: unset !important;
    margin: unset !important;
    width: unset !important;
  }
}
.form-checbox-n {
  min-width: 230px !important;
  margin-right: 70px;
}

.create-content-main {
  .row-input {
    .codex-editor input {
      margin: 5px 0 10px 0;
    }
  }
}
.add-btn {
  background-color: #3838aa;
  color: white;
}
.bt-sub {
  width: 32px;
  height: 32px;
  margin-top: 3px !important;
  margin-left: 10px;
}
.bt-setting {
  margin-top: 0px !important;
  width: 40px;
  height: 40px;
}
.btn-secondary:focus {
  background-color: #3838aa;
  color: white;
  box-shadow: unset;
}
.cus-centre {
  align-items: center;
  display: flex;
}
.cus-inp {
  padding-right: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.cus-bt-mov {
  margin-top: 14px;
  margin-right: 10px;
}
</style>
